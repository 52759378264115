import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatosService } from 'src/app/services/platos.service';
import { GLOBAL } from '../../services/global';

declare const google: any;

@Component({
  selector: 'app-mapa-marca',
  templateUrl: './mapa-marca.component.html',
  styleUrls: ['./mapa-marca.component.css']
})
export class MapaMarcaComponent implements OnInit {

  url = "";
  pos: any={};
  map: any;
  marca: String;
  markers:Array<any>=[];
  restaurantsArray: any=[];
  iconMyLocation:string="/assets/img/my_location.png";

  constructor(
    private route: ActivatedRoute,
    private pService:PlatosService
  ) { }

  ngOnInit() {
    this.url = GLOBAL.url;
    this.restaurantsArray = [];

    this.chargeMap();

    this.route.params.subscribe(result => {
      this.marca = result.marca;
    });

    //Primero debemos preguntar si este hashtag está activo en la configuración

    this.pService.marcaValidaMapa(this.marca).subscribe(resultMapa => {
      if(resultMapa == 1) {
        this.pService.getPlatosByMarca(this.marca).subscribe(result => {
          this.restaurantsArray = result;
          for(let i=0;i<(<any>this.restaurantsArray).length;i++){
            let device = this.restaurantsArray[i];
           
            this.markers[device.restaurante.id]=new google.maps.Marker({
              position:{lat:parseFloat(device.restaurante.lat),lng:parseFloat(device.restaurante.lng)},
              map:this.map,
              //title:device.name
            });
      
            this.markers[device.restaurante.id].addListener("click", () => {
              this.selectedDevice(device.restaurante);
              this.infoWindow(device.restaurante, this.markers[device.restaurante.id]);
            });
          }

          this.seeAllMarker();
        });
      }
    });
  }

  seeAllMarker(){
    let bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < this.restaurantsArray.length; i++) {
      if(this.markers[this.restaurantsArray[i].restaurante.id]!=undefined){
        let pos=this.markers[this.restaurantsArray[i].restaurante.id].getPosition();
        if((!isNaN(pos.lat()))&&(!isNaN(pos.lng()))){
          bounds.extend(pos);
        }
      }
    }

    this.map.fitBounds(bounds);
  }

  chargeMap=() => {

    this.map = new google.maps.Map(document.getElementById('map'),{ 
      center:{lat:40.4167754,lng:-3.7037902},
      zoom: 18,
      streetViewControl: true,
      clickableIcons: false
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=>{
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.map = new google.maps.Map(document.getElementById('map'), { 
          center: this.pos,
          zoom: 18,
          streetViewControl: true,
          clickableIcons: false
        });

        new google.maps.Marker({
          position:this.pos,
          map:this.map,
          icon:{
            url:this.iconMyLocation,
            size: new google.maps.Size(20, 20),
            scaledSize: new google.maps.Size(20, 20),
            labelOrigin: new google.maps.Point(20, -20)
          },
        });
      });
    } else {
      alert("Browser doesn't support Geolocation");
    }
  }

  selectedDevice(device:any){
    if(this.markers[device.id]!=undefined){
      var pos = {
        lat: parseFloat(device.lat),
        lng: parseFloat(device.lng)
      };
      this.map.setZoom(20);
      this.map.setCenter(pos);
    }
  }

  infoWindow(device, marker) {
   
    var contenidoInfo = '<div style="display:flex;">'+
                          '<div style="flex:1;">'+
                            '<img style="width: 100%;height: 100%;object-fit: cover;" src="'+GLOBAL.url+device.fotoPerfil+'" onerror="this.onerror=null; this.src=\''+GLOBAL.url+'no-photo-available.png\'" />'+
                          '</div>'+
                          '<div style="flex:1;margin-left: 10px;">'+
                            '<label style="margin-left: 0px; font-size: 15px;font-weight: bold;flex:1;margin-bottom: 0px;">'+
                              device.nombre+
                            '</label>'+
                            '<p style="margin-top: 10px;font-size: 12px;color: #E35100;">'+
                              '<b>'+device.tipo_local+'</b>'+
                            '</p>'+
                            '<p style="font-size: 12px;color: #E35100;">'+
                                '<b>'+device.tipo_comidas+'</b>'+
                            '</p>'+
                            '<p>'+
                              '<b>'+device.direccion+'</b>'+
                            '</p>'+
                            '<button style="color:white;padding: 4px;border-radius: 0px; background-color:#E35100" onclick="window.location.href = \'restaurante/'+device.id+'\';">Ver Carta</button>'+
                          '</div>'
                        '</div>';
    var infowindow = new google.maps.InfoWindow();
    infowindow.setContent(contenidoInfo);
    infowindow.setOptions({maxWidth: 450});
    infowindow.open(this.map, marker);
  }

  onClickCerca(){
    this.map.setCenter(this.pos);
    this.map.setZoom(8);
  }

}
