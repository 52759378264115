import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Producto } from 'src/app/models/producto.model';
import { Fabricante } from 'src/app/models/fabricante.model';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ProductoService } from 'src/app/services/producto.service';
import { FollowService } from 'src/app/services/follow.service';
import { GLOBAL } from '../../services/global';

@Component({
  selector: 'app-pagina-producto',
  templateUrl: './pagina-producto.component.html',
  styleUrls: ['./pagina-producto.component.css']
})
export class PaginaProductoComponent implements OnInit {

  arrayBotones = [];
  producto: Producto;
  fabricante: Fabricante;
  urlResources = GLOBAL.urlResourcesCarta;
  urlIcons = GLOBAL.urlIcons;
  url = "";
  closeResult: string;
  tagsMarca = [];
  tagsNormal = [];
  alergenos = [];
  newTagNormal: string;
  listTagNormal = [];
  tempMarca: any[];
  tempNormal: any[];
  follows: string;
  userLogin:any;
  idProducto:number;
  resultInsertFollow: any;

  @ViewChild("btnLike", null) btnLike: {nativeElement: any;}

  constructor(
    private route: ActivatedRoute,
    private pService: ProductoService,
    private fService: FollowService,
  ) {
    this.producto = new Producto;
    this.fabricante = new Fabricante;
   }

  ngOnInit() {
    this.userLogin=JSON.parse(localStorage.getItem("user"));

    this.url = GLOBAL.url;
    this.route.params.subscribe(result => {
      this.idProducto=result.id;
      this.getInfoProducto(result.id);     
    });
  }

  onClickLike(){
    this.fService.setSeguidorPlato(this.idProducto, this.userLogin.id).subscribe(result => {
      this.resultInsertFollow = result;

      if(this.resultInsertFollow.result=="ok"){
        $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
        $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
      }
    });
}

// OBTENER LISTADO DE PLATOS DESDE LARAVEL
getInfoProducto(id) {
  this.pService.getInfoProducto(id).subscribe(result => {
    this.producto = result;
    
    this.producto.id_fabricante = JSON.parse(result.id_fabricante);
    this.producto.descripcion = result.descripcion;
    this.tempMarca = result.hashtag;
    this.producto.alergenos = JSON.parse(result.alergenos);
    
    //Se inicializa la variable restaurante
    this.fabricante.id = this.producto.id_fabricante;
    this.fabricante.fotoPortada = result.fabricante.fotoPortada;
    this.fabricante.nombre = result.fabricante.nombre;
    this.fabricante.direccion = result.fabricante.direccion;
    // Botones del menú principal
    this.arrayBotones = GLOBAL.arrayBotonesFabri(this.producto.id_fabricante);
  
    //Nos traemos los seguidores que tiene este plato
    this.fService.getTotalSeguidoresProductos(id).subscribe(result2 => {
      this.follows = result2;
    });

    this.fService.setSeguidorProducto(this.idProducto, this.userLogin.id).subscribe(result => {
      this.resultInsertFollow = result;

      if(this.resultInsertFollow.result=="exists"){
        $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
        $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
      }
    });

  });
}

/* // ABRIR | CERRAR MODAL EDICIÓN INFO
open(content) {
  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  
    if(this.listTagNormal) {
      console.log(this.newTagNormal);
      for (let i = 0; i < this.listTagNormal.length; i++) {
        this.plato.tagsNormal.push(this.listTagNormal[i]);
      }
    }
    console.log('final dish', this.plato);
    // ENVIAR INFO PLATO A LARAVEL
    this.pService.editInfoPlato(this.plato).subscribe(result => {
      console.log(result);
    });
    this.tempNormal = [];
    this.tempMarca = [];
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}

// AÑADIR NUEVO TAG NORMAL 
addNuevoTag(tag) {
  let t = new Tags;
  t.tipo = 'normal';
  t.label = tag;
  this.listTagNormal.push(t);
  this.newTagNormal = '';
} */

}
