//import { google } from '@agm/core/services/google-maps-types';
import { Component, Input, OnInit } from '@angular/core';
import { throwToolbarMixedModesError } from '@angular/material';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RestauranteService } from 'src/app/services/restaurante.service';

import { GLOBAL } from '../../../services/global';

declare const google: any;

@Component({
  selector: 'app-restaurantes',
  templateUrl: './restaurantes.component.html',
  styleUrls: ['./restaurantes.component.css']
})


export class RestaurantesComponent implements OnInit {

  map: any;
  isMobile:any;
  restaurantsArray: any=[];
  markers:Array<any>=[];
  pos: any=null;
  iconMyLocation:string="/assets/img/my_location.png";

  url = "";

  @Input() item = ''; 
  @Input() searchBar = '';

  constructor(
    private deviceDetector:DeviceDetectorService,
    private resService: RestauranteService,
  ) {

   }

  ngOnInit() {
    console.log(this.item);
    this.url = GLOBAL.url;

    this.isMobile=this.deviceDetector.isMobile();

    //this.allRestaurants(this.item);

  }

  ngAfterViewInit(): void {
    this.chargeMap();
  }

  ngOnChanges(changes:any) {
    this.restaurantsArray = [];
    var seccionItem = changes.item ? changes.item.currentValue : this.item;

    this.allRestaurants(seccionItem);
  }

  allRestaurants=(tipo) => {
    this.chargeMap();
    switch(tipo) {
      case 'carta': {
        this.resService.getAllRestaurantsCarta(this.searchBar).subscribe(result => {
          this.restaurantsArray=result;
          this.runPrintMap();
        });
       break;
      }
      case 'productos': {
        this.resService.getAllRestaurantsProducto(this.searchBar).subscribe(result => {
          this.restaurantsArray=result;
          this.runPrintMap();
        });
        break;
      }
      case 'restaurantes': {
        this.resService.getAllRestaurantsRestaurante(this.searchBar).subscribe(result => {
          this.restaurantsArray=result;
          this.runPrintMap();
        });
        break;
      }
      case 'usuarios': {
        this.resService.getAllRestaurantsUsuario(this.searchBar).subscribe(result => {
          this.restaurantsArray=result;
          this.runPrintMap();
        });
         break;
      }
    }
    
  }

  chargeMap=() => {

    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 18,
      streetViewControl: true,
      clickableIcons: false
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=>{
        this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.map = new google.maps.Map(document.getElementById('map'), { 
          center: this.pos,
          zoom: 6,
          streetViewControl: true,
          clickableIcons: false
        });

        new google.maps.Marker({
          position:this.pos,
          map:this.map,
          icon:{
            url:this.iconMyLocation,
            size: new google.maps.Size(20, 20),
            scaledSize: new google.maps.Size(20, 20),
            labelOrigin: new google.maps.Point(20, -20)
          },
        });

      });
    } else {
      alert("Browser doesn't support Geolocation");
    }
  }

  runPrintMap=() => {
    for(let i=0;i<(<any>this.restaurantsArray).length;i++){
      let restaurant = this.restaurantsArray[i];
      this.markers[restaurant.id]=new google.maps.Marker({
        position:{lat:parseFloat(restaurant.lat),lng:parseFloat(restaurant.lng)},
        map:this.map
      });

      this.markers[restaurant.id].addListener("click", () => {
        this.selectedDevice(restaurant);
        this.infoWindow(restaurant, this.markers[restaurant.id]);
      });
    }

    if(this.pos==null){
      this.seeAllMarker();
    }
    

  }

  seeAllMarker(){
    let bounds = new google.maps.LatLngBounds();

    for (let i = 0; i < this.restaurantsArray.length; i++) {
      if(this.markers[this.restaurantsArray[i].id]!=undefined){
        bounds.extend(this.markers[this.restaurantsArray[i].id].getPosition());
      }
    }

    this.map.fitBounds(bounds);
  }

  selectedDevice(device:any){
    if(this.markers[device.id]!=undefined){
      var pos = {
        lat: parseFloat(device.lat),
        lng: parseFloat(device.lng)
      };
      this.map.setZoom(20);
      this.map.setCenter(pos);
    }
  }

  infoWindow(device, marker) {
   
    var contenidoInfo = '<div style="display:flex;">'+
                          '<div style="flex:1;">'+
                            '<img style="width: 100%;height: 100%;object-fit: cover;" src="'+GLOBAL.url+device.fotoPerfil+'" onerror="this.onerror=null; this.src=\''+GLOBAL.url+'no-photo-available.png\'" />'+
                          '</div>'+
                          '<div style="flex:1;margin-left: 10px;">'+
                            '<label style="margin-left: 0px; font-size: 15px;font-weight: bold;flex:1;margin-bottom: 0px;">'+
                              device.nombre+
                            '</label>'+
                            '<p style="margin-top: 10px;font-size: 12px;color: #E35100;">'+
                              '<b>'+device.tipo_local+'</b>'+
                            '</p>'+
                            '<p style="font-size: 12px;color: #E35100;">'+
                                '<b>'+device.tipo_comidas+'</b>'+
                            '</p>'+
                            '<p>'+
                              '<b>'+device.direccion+'</b>'+
                            '</p>'+
                            '<button style="color:white;padding: 4px;border-radius: 0px; background-color:#E35100" onclick="window.location.href = \'restaurante/'+device.id+'\';">Ver Carta</button>'+
                          '</div>'
                        '</div>';
    var infowindow = new google.maps.InfoWindow();
    infowindow.setContent(contenidoInfo);
    infowindow.setOptions({maxWidth: 450});
    infowindow.open(this.map, marker);
  }
}


