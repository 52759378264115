import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PlatosService } from 'src/app/services/platos.service';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Restaurante } from 'src/app/models/restaurante';
import { GLOBAL } from '../../services/global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { PublicacionService } from 'src/app/services/publicacion.service';

@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.css']
})
export class BuscadorComponent implements OnInit {
  url="";
  restaurante: Restaurante;
  restaurantes = [];
  verElementos:any="carta";
  varSearchBar:any='';
  isMobile:boolean=false;
  cartaQR:boolean=false;
  varFiltro: MatListOption[];
  labelFiltro: any;
  tagFiltro: any;
  applyFilters = [];
  nBus:any = 'buscadorHash';
  hashtagsMarca:any = [];

  divMap:boolean = false;
  divPhoto:boolean = true;
  divCard:boolean = false;
  divFilter:boolean = false;
  divSort:boolean = false;

  @ViewChild("containergrid",null) containerGrid: {nativeElement: any;}

  @Output() filtros = new EventEmitter<MatListOption[]>();

  public optionsBuscador: Select2Options;

  constructor(
    private rService: RestauranteService,
    private pService: PlatosService,
    private deviceDetector:DeviceDetectorService,
    private puService:PublicacionService,
  ) { 
    this.restaurante = new Restaurante;
  }

  ngOnInit() {
    
    this.url=GLOBAL.url;
    this.isMobile=this.deviceDetector.isMobile();

    this.puService.getAllHashtagMarca().subscribe(result => {
      this.hashtagsMarca = result;
    });

    this.optionsBuscador = {
      multiple: true,
      placeholder: '#hashtag',
      id: this.nBus,
    }

  }

  showMap(){
    this.divMap=true;
    this.divCard=false;
    this.divPhoto=false;
    this.divFilter=false;
    this.divSort=false;
  }

  showPhotos(){
    this.divMap=false;
    this.divPhoto=true;
    this.divCard=false;
    this.divFilter=false;
    this.divSort=false;
  }

  showCard(){
    this.divMap=false;
    this.divPhoto=false;
    this.divCard=true;
    this.divFilter=false;
    this.divSort=false;
  }

  showFilter(){
    this.divMap=false;
    this.divPhoto=false;
    this.divCard=false;
    this.divFilter=true;
    this.divSort=false;
  }

  showSort(){
    this.divMap=false;
    this.divPhoto=false;
    this.divCard=false;
    this.divFilter=false;
    this.divSort=true;
  }

  fFilterSelected(value: any){
    this.varFiltro = value;
  }
}

