// import { MapaComponent } from './../mapa/mapa.component';
import { Component, OnInit, Injectable, ViewChild, ElementRef, Input } from '@angular/core';

import { Filtro } from 'src/app/models/filtro';
import { GetPhotosService } from "../../services/get-photos.service";
import { ModalService } from '../../components/_modal/modal.service';
import { FiltrosService } from 'src/app/services/filtros.service';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { PlatosService } from '../../services/platos.service';
import { ProductoService } from '../../services/producto.service';
import { UsuariosService } from '../../services/usuarios.service';
import { MapaService } from '../../services/mapa.service';
import { PublicacionService } from '../../services/publicacion.service';
import { FollowService } from '../../services/follow.service';
import { GLOBAL } from '../../services/global';
import { Router } from '@angular/router';
import { MatSelectionList, MatListOption } from '@angular/material/list';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-seccion-resultados',
  templateUrl: './seccion-resultados.component.html',
  styleUrls: ['./seccion-resultados.component.css']
})
/**
 * 
 * CONTROLADOR PERTENECIENTE AL SLIDER DEL LADO DE LA IZQUIERDA
 * 
 * AÑADIR FOTO ESTANDAR NO IMAGE CUANDO MNO EXISTA UNA IMAGEN
 * 
 */
export class SeccionResultadosComponent implements OnInit {
  @ViewChild('listaFiltrosLocales', { static: false }) listaFiltrosLocales: ElementRef;
  //@ViewChild('mat-card', {static: false}) var : any;
  //QUITAN BASE64 DE SUBNOMBRE Y CREAR UN NUEVO CAMPO

  campo1: string;
  campo2: string;
  campo3: string;
  campo4: string;
  campo5: string;
  tempMarcadores = [];
  public selected: any;
  marcadores = [];
  public cerrado: any;
  public photosBase64: any;
  public fotoRestauranteLista: any;
  public parseNames = new Array();
  public i: any;
  public filterArray = [];
  public map = new Map();
  resultOk: boolean = true;
  t = [];
  urlM = "";

  imgSeccion = [];
  @Input() item = '';
  @Input() searchBar = '';
  @Input() filtro : MatListOption[];

  constructor(
    private mapServices: MapaService,
    private gphotoService: GetPhotosService,
    private modalService: ModalService,
    private fService: FiltrosService,
    private rService: RestauranteService,
    private pService: PlatosService,
    private proService: ProductoService,
    private uService: UsuariosService,
    private puService: PublicacionService,
    private foService: FollowService,
    private router: Router,
  ) {
    /**
     * MARCADORES CONTIENE EL NOMBRE DEL LOCAL EL CUAL SE ENVIARA AL BACKEND PARA ACCEDER A LAS FOTOS
     */

    this.campo1 = 'campo1';
    this.campo2 = 'campo2';
    this.campo3 = 'campo3';
    this.campo4 = 'campo4';
    this.campo5 = 'campo5';
  }

  /**
   * Modal para la reserva de restaurante
   * @param id 
   */
  openModal(id: string) {
    this.modalService.open(id);
  }
  /**
   * Modal para la reserva de restaurante
   * @param id 
   */
  closeModal(id: string) {
    this.modalService.close(id);
  }


  ngOnInit() {
    this.urlM = GLOBAL.url;
    //console.log("Es aquí: " + this.urlM);
    //HACE 2 PETICIONES ARREGLAR
    //RECOGE TODA LA INFO DE LOS LOCALES
    //ENTRA CADA VEZ QUE SE REPOSICIONA EL MAPA
    this.mapServices.currentMessage.subscribe(response => {
      this.marcadores = response;
      this.tempMarcadores = response;
      this.gphotoService.getPhotosPortadaNoAsync(this.marcadores).subscribe(f => this.getBase64(f));
      console.log('marcadores', this.marcadores);
      this.urlM = GLOBAL.url;

      
    });
  };

  ngOnChanges(changes:any) {
    var seccionItem = changes.item ? changes.item.currentValue : this.item;
    this.imgSeccion = []
    switch(seccionItem) {
      case 'carta': {this.generarFichasSeccionesCarta(this.searchBar, this.filtro); break;}
      case 'productos': {this.generarFichasSeccionesProductos(this.searchBar, this.filtro); break;}
      case 'restaurantes': {this.generarFichasSeccionesRestaurantes(this.searchBar); break;}
      case 'usuarios': {this.generarFichasSeccionesUsuarios(this.searchBar); break;}
    }    
  }

  generarFichasSeccionesCarta=(searchBar, filtros)=>{
    this.pService.getPhotosPlatos(searchBar, filtros).subscribe(result => {
      for (let valor of Object.values(result)) {
          this.imgSeccion.push({thumbImage:GLOBAL.url+valor['img']
          , idSeccion: valor['id']
          , nombrePlato: valor['nombre_plato']
          , precioPlato: valor['precio_plato']
          , nombreRestaurante: valor['restaurante']['nombre']
          , totalSeguidores: valor['follows_count']
          , etiquetas: JSON.parse(valor['tags'])
        });
      }
    });
  };

  generarFichasSeccionesProductos=(searchBar, filtro)=>{
    this.proService.getPhotosProductos(searchBar, filtro).subscribe(result => {
      console.log(result);
      for (let valor of Object.values(result)) {
          this.imgSeccion.push({thumbImage:GLOBAL.url+valor['img']
          , idSeccion: valor['id']
          , nombrePlato: valor['nombre']
          , precioPlato: /*valor['precio_plato']*/ 0
          , nombreRestaurante: valor['fabricante']['nombre']
          , totalSeguidores: valor['follows_count']
          , etiquetas: valor['hashtag']
          });
      }
    });
  };

  generarFichasSeccionesRestaurantes=(searchBar)=>{
    this.rService.getPhotosRestaurantes(searchBar).subscribe(result => {
      for (let valor of Object.values(result)) {
          this.imgSeccion.push({thumbImage:GLOBAL.url+valor['fotoPerfil']
          , idSeccion: valor['id']
          , nombreRestaurante: valor['nombre']
          , tipoRestaurante: valor['tipo_local']
          , tipoComidas: valor['tipo_comidas']
          , direccionRest: valor['direccion']
        });
      }
    });
  };

  generarFichasSeccionesUsuarios=(searchBar)=>{
    this.uService.getPhotosUsuarios(searchBar).subscribe(result => {
      for (let valor of Object.values(result)) {
          this.imgSeccion.push({thumbImage:GLOBAL.url + valor['img']
          , idSeccion: valor['id']
          , nombreRestaurante: valor['restaurante']['nombre']
          , nombreUsuario: valor['user']['name']
          , descripcion: valor['descripcion']
          , hashtag: valor['hashtag']
        });
      }
    });
  };

  goDetailSeccion(idTipo, item) {
    
    switch(item) {
      case 'carta': {this.router.navigate(['plato/'+ idTipo]); break;}
      case 'productos': {this.router.navigate(['producto/'+ idTipo]); break;}
      case 'restaurantes': {this.router.navigate(['restaurante/'+ idTipo]); break;}
      case 'usuarios': {this.router.navigate(['publicacion/'+ idTipo]); break;} 
    }
  }



  getID() {
    //console.log("----------------->" + id);
  }


  getBase64(f: any) {

    if (f == "*** no data provide ***") {

      console.log(f);

    } else {

      for (let image in f) {//RECORRE LAS IMAGENES DISPONIBLE EN BASE64

        for (let index = 0; index < this.marcadores.length; index++) {

          if (this.marcadores[index]["id"] == image) {
            /**
             * safe decode un SRC
             */
            this.marcadores[index]["portada"] = f[image];
          }
        }
      }
    }
  }

  getInfoRestaurante() {

  }

  applyFiltro(nombre: any, label: any, tag: any, array?: any) {
    let filter = new Filtro;

    if (label == null) {
      this.marcadores = this.tempMarcadores;
      this.resultOk = true;
      this.t = [];
    } else {

      // if (tag !== 'menu_del_dia' && tag !== 'take_away' && tag !== 'reservas') {
      if (array) {
        this.fService.aplicarFiltroArray(array).subscribe(result => {
          console.log('resultados', result);
          if (result.length === 0) {
            this.resultOk = false;
          } else {
            this.resultOk = true;
          }
          this.marcadores = result;
          console.log(this.marcadores);
        });
      } else {
        filter.nombre = nombre;
        filter.label = label;
        filter.tag = tag;
        this.fService.aplicarFiltro(filter).subscribe(result => {
          if (result.length === 0) {
            this.resultOk = false;
          } else {
            this.resultOk = true;
          }
          this.marcadores = result;
          console.log(this.marcadores);
        });
      }
    }
  }

  trackByMarcador(index: number, marcador: any): string {
    return marcador.id;
  }
}



