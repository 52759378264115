export const GLOBAL = {
    id: '',
    
    //url:'http://uati.atech/', /* LOCAL */
    url:'https://api.uati.dev.atech.es/', /* DESARROLLO */
    
    //urlApi: 'http://uati.atech/api/', /* LOCAL */
    urlApi: 'https://api.uati.dev.atech.es/api/', /* DESARROLLO */
    
    //urlAdmin:'http://uati.atech/admin/',
    urlAdmin: 'https://api.uati.dev.atech.es/admin/', /* DESARROLLO */
    
    //urlApi: 'https://api.uati.dev.atech.es/api/restaurante/',
    urlApiValidate: 'https://api.uati.dev.atech.es/api/user/',
    urlResourcesCarta: 'https://api.uati.dev.atech.es/images-restaurante/',
    urlIcons: 'https://api.uati.dev.atech.es/images-restaurante/icons/',
    urlQRCarta: 'https://localhost:4200/restaurante/',
    arrayBotones(id) {
        let arrayBotones = [
            {
                label: 'Carta',
                href: '/restaurante/' + id
            },
            {
                label: 'Información',
                href: '/restaurante/' + id + '/info'
            },
            /*{
                label: 'Reservas',
                href: '/restaurante/' + id + '/reserva'
            }*/
        ];
        return arrayBotones;
    },
    arrayBotonesQR(id) {
        let arrayBotones = [
            {
                label: 'Carta',
                href: '/restauranteQR/' + id
            },
            {
                label: 'Información',
                href: '/restauranteQR/' + id + '/info'
            },
            /*{
                label: 'Reservas',
                href: '/restaurante/' + id + '/reserva'
            }*/
        ];
        return arrayBotones;
    },
    arrayBotonesFabri(id) {
        let arrayBotones = [
            {
                label: 'Productos',
                href: '/fabricante/' + id
            },
            {
                label: 'Información',
                href: '/fabricante/' + id + '/info'
            },
            /*{
                label: 'Reservas',
                href: '/restaurante/' + id + '/reserva'
            }*/
        ];
        return arrayBotones;
    },
    arrayBotonesUser(id) {
        let arrayBotones = [
            {
                label: 'Usuario',
                href: '/usuario/' + id
            },
            /* {
                label: 'Restaurante',
                href: '/restaurante/' + id_res
            }, */
        ];
        return arrayBotones;
    },
    arrayUbicaciones() {
        let arrayUbicaciones = [
            {
                label: 'Comedor'
            },
            {
                label: 'Terraza'
            },
            {
                label: 'Barra'
            }
        ];
        return arrayUbicaciones;
    },
    arrayMesas() {
        let arrayMesas = [
            {
                ubicacion: 'comedor',
                cantidad: 10,
                max: 4,
                min: 2
            },
            {
                ubicacion: 'comedor',
                cantidad: 2,
                max: 2,
                min: 1
            },
            {
                ubicacion: 'terraza',
                cantidad: 6,
                max: 4,
                min: 2
            },
            {
                ubicacion: 'terraza',
                cantidad: 2,
                max: 2,
                min: 1
            }
        ];
        return arrayMesas;
    },

    filtrosGlobales: [] = "",
}