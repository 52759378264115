import { Component, OnInit,Input,ViewChild} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'modal-mensaje',
  templateUrl: './modal-mensaje.component.html',
  styleUrls: ['./modal-mensaje.component.css']
})
export class ModalMensajeComponent implements OnInit {

  isMobile:boolean=false;

  @Input() titulo: string;
  @Input() mensaje: string;

  @ViewChild("divTransparente",{static: false}) mensajeModal: { nativeElement: any; };
  @ViewChild("divMensaje",{static: false}) divMensaje: { nativeElement: any; };

  constructor(private deviceDetector:DeviceDetectorService) {
    
  }

  ngOnInit() {
    this.isMobile=this.deviceDetector.isMobile();
  }

  

  cerrar=()=>{
    $(this.mensajeModal.nativeElement).css("display","none");
  }

  ngAfterViewInit(){
    if(this.isMobile){
      $(this.divMensaje.nativeElement).css("width","90%");
    }
  }

}

