import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Restaurante } from 'src/app/models/restaurante';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from '../../services/global';
import { FiltrosService } from 'src/app/services/filtros.service';
import { rest } from 'lodash';

@Component({
  selector: 'app-info-restaurante',
  templateUrl: './info-restaurante.component.html',
  styleUrls: ['./info-restaurante.component.css']
})

export class InfoRestauranteComponent implements OnInit {
  arrayBotones = [];
  arrayFiltros = [];
  id: any;
  restaurante: Restaurante;
  closeResult: string;
  url = "";
  cartaQR:boolean=false;


  constructor(
    private route: ActivatedRoute,
    private rService: RestauranteService,
    private modalService: NgbModal,
    private fService: FiltrosService
  ) {
    this.restaurante = new Restaurante();
   }

  ngOnInit() {
    this.url = GLOBAL.url
    this.route.params.subscribe(result => {
      this.arrayBotones = GLOBAL.arrayBotones(result.id);
      this.id = result.id;
    });
    let currentUrl=window.location.href;
    if(currentUrl.indexOf("QR")!=-1){
      this.cartaQR=true;
      this.arrayBotones = GLOBAL.arrayBotonesQR(this.id);
    }

    this.getInfoRestaurante(this.id);

    
  }

  getInfoRestaurante(id: any) {
    // OBTENER DATOS DEL RESTAURANTE
    this.rService.getInfoRestaurante(id).subscribe(result => {      
      this.restaurante = result;
      console.log("restaurante: " + result.tipo_cocina);
      this.restaurante.telefonofijo = result.telefonofijo;
      this.restaurante.telefonomovil = result.telefonomovil;    
      this.restaurante.dietas = JSON.parse(result.dietas);
      this.restaurante.menu_del_dia = JSON.parse(result.menu_del_dia);
      this.restaurante.ideal_para = JSON.parse(result.ideal_para);
      this.restaurante.servicios = JSON.parse(result.servicios);
      this.restaurante.mas_servicios = JSON.parse(result.mas_servicios);
      this.restaurante.reservas = result.reservas;
      this.restaurante.take_away = result.take_away;
      this.restaurante.take_away_link = result.take_away_link;
      this.restaurante.tipo_cocina = result.tipo_cocina;
      this.restaurante.local = JSON.parse(result.local);    
      this.restaurante.espacios = result.espacios;

      
    });
  }
}
