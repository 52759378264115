import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { GLOBAL } from '../../services/global';
import { FollowService } from 'src/app/services/follow.service';
import { User } from 'src/app/models/user';
import { PublicacionService } from 'src/app/services/publicacion.service';
import { Publicacion } from 'src/app/models/publicacion.model';

@Component({
  selector: 'app-info-usuario',
  templateUrl: './info-usuario.component.html',
  styleUrls: ['./info-usuario.component.css']
})
export class InfoUsuarioComponent implements OnInit {
  
  usuario: User;
  publicacion: Publicacion
  arrayBotones = [];
  url="";
  secciones = [];
  closeResult: string;
  nuevaSeccion: any;
  selectedFile: File = null;
  imageSrc: any;
  filesToUpload: any;
  file: any;
  textImg: any;
  textPrecio: string;
  urlCarta: string;
  qrOk: boolean = false;
  tempGuid: string;
  arrayCarta: any;
  cartaOk: boolean = false;
  tempSeccion: any;
  admin: boolean;
  visibilidadSecciones:Array<any>=[];
  follows: string;
  userLogin:any;
  resultInsertFollow: any;

  @ViewChild("btnLike", null) btnLike: {nativeElement: any;}

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private filService: FollowService,
    private pService: PublicacionService
  ) {
    this.usuario = new User;
   }

  ngOnInit() {
    this.userLogin=JSON.parse(localStorage.getItem("user"));
    this.url=GLOBAL.url;
    this.admin = false;
    this.route.params.subscribe(result => {
      this.usuario.id = result.id;
      this.arrayBotones = GLOBAL.arrayBotonesUser(result.id);
      
    });

    this.pService.getInfoPublicacionUsuario(this.usuario.id).subscribe(result => {
      console.log('result>', result);
      this.usuario = result[0].user;
      this.publicacion = result;
      this.generarImagenesSecciones(this.publicacion);


      console.log(this.publicacion);
      
      this.filService.getTotalSeguidoresUsuario(this.usuario.id).subscribe(result2 => {
        this.follows = result2;
      });
      this.filService.setSeguidorUsuario(this.usuario.id, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if(this.resultInsertFollow.result=="exists"){
          $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
          $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
        }
      });

    });
  }

  generarImagenesSecciones=(publicacion)=>{
    console.log('result>', publicacion);
    for(let i=0; i<(publicacion).length;i++){
      let fotosSeccion=[];
      
        fotosSeccion.push({thumbImage:GLOBAL.url+(publicacion)[i].img
            , descripcion:(publicacion)[i].descripcion
            , hashtag:(publicacion)[i].hashtag
            , nombreRestaurante:(publicacion)[i].restaurante.nombre
        });
      (publicacion)[i].fotoProductos=fotosSeccion;
    }
  }

  goDetailProducto(idTipo, item) {
    
    switch(item) {
      case 'carta': {this.router.navigate(['plato/'+ idTipo]); break;}
      case 'productos': {this.router.navigate(['producto/'+ idTipo]); break;}
      case 'restaurantes': {this.router.navigate(['restaurante/'+ idTipo]); break;}
      case 'usuarios': {this.router.navigate(['publicacion/'+ idTipo]); break;} 
    }
  }



}
