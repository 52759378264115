import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Restaurante } from 'src/app/models/restaurante';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from '../../services/global';
import { PlatosService } from 'src/app/services/platos.service';
import { FollowService } from 'src/app/services/follow.service';

interface Fotos {
  image: string;
  thumbImage: string;
  title: string;
}

@Component({
  selector: 'app-pagina-bar',
  templateUrl: './pagina-bar.component.html',
  styleUrls: ['./pagina-bar.component.css']
})
export class PaginaBarComponent implements OnInit {
  @ViewChild('imgQR', { static: true }) imgQR: ElementRef;

  restaurante: Restaurante;
  arrayBotones = [];
  url="";
  secciones = [];
  closeResult: string;
  nuevaSeccion: any;
  selectedFile: File = null;
  imageSrc: any;
  filesToUpload: any;
  file: any;
  textImg: any;
  textPrecio: string;
  urlCarta: string;
  qrOk: boolean = false;
  tempGuid: string;
  arrayCarta: any;
  cartaOk: boolean = false;
  tempSeccion: any;
  admin: boolean;
  visibilidadSecciones:Array<any>=[];
  follows: string;
  userLogin:any;
  resultInsertFollow: any;
  cartaQR:boolean=false;
  follow:boolean=false;

  @ViewChild("btnLike", null) btnLike: {nativeElement: any;}
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private rService: RestauranteService,
    private modalService: NgbModal,
    private pService: PlatosService,
    private fService: FollowService,) {
    this.restaurante = new Restaurante;

  }

  ngOnInit() {
    this.userLogin=JSON.parse(localStorage.getItem("user"));
    this.url=GLOBAL.url;
    this.admin = false;
    this.route.params.subscribe(result => {
      this.restaurante.id = result.id;
      this.arrayBotones = GLOBAL.arrayBotones(result.id);
    });

    let currentUrl=window.location.href;

    if(currentUrl.indexOf("QR")!=-1){
      this.cartaQR=true;
      this.arrayBotones = GLOBAL.arrayBotonesQR(this.restaurante.id);
    }

    // OBTENER DATOS DEL RESTAURANTE
    this.rService.getInfoRestaurante(this.restaurante.id).subscribe(result => {
      console.log('result>', result);
      this.restaurante = result;
      this.generarImagenesSecciones(this.restaurante);
      this.generarArrayVisibilidad();
      this.fService.getTotalSeguidoresRestaurante(this.restaurante.id).subscribe(result2 => {
        this.follows = result2;
      });
      this.fService.setSeguidorRestaurante(this.restaurante.id, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if(this.resultInsertFollow.result=="exists"){
          this.follow=true;
          $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Siguiendo');
          $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
        }
      });

    });
  }

  generarArrayVisibilidad(){
    for(let seccion of this.restaurante.secciones){
      this.visibilidadSecciones[(<any>seccion).id]=false;
    }
  }

  generarImagenesSecciones=(restaurante)=>{
    console.log('result>', restaurante);
    for(let i=0; i<(<any>this.restaurante).secciones.length;i++){
      let fotosSeccion=[];
      for(let z=0;z<(<any>this.restaurante).secciones[i].platos_secciones.length;z++){
        fotosSeccion.push({thumbImage:GLOBAL.url+(<any>this.restaurante).secciones[i].platos_secciones[z].plato.img
            , descripcion:(<any>this.restaurante).secciones[i].platos_secciones[z].plato.descripcion
            , alergenos:(<any>this.restaurante).secciones[i].platos_secciones[z].plato.alergenos
            , nombrePlato:(<any>this.restaurante).secciones[i].platos_secciones[z].plato.nombre_plato
            , precioPlato:(<any>this.restaurante).secciones[i].platos_secciones[z].plato.precio_plato
            , hastagPlatoMarca:this.obenerTiposTags((<any>this.restaurante).secciones[i].platos_secciones[z].plato.tags, 'marca')
            , hastagPlatoNormal:this.obenerTiposTags((<any>this.restaurante).secciones[i].platos_secciones[z].plato.tags, 'normal')
            , guidPlato:(<any>this.restaurante).secciones[i].platos_secciones[z].plato.guid
            , idPlato:(<any>this.restaurante).secciones[i].platos_secciones[z].plato.id
        });
      }
      (<any>this.restaurante).secciones[i].fotoPlatos=fotosSeccion;
    }
  }

  imagenPerfilError=(event)=>{
    event.target.src = GLOBAL.url+"no-photo-available.png";
  }

  // CLICK EN IMAGEN
  imageClick(event) {
    console.log("ir al plato");
  }

  obtenerDescripcionPlatoJSON = (cadena) => {
    //Devolvemos el valor del JSON que recibimos
    return cadena == null ? '' : JSON.parse(cadena)[0].data;
  }

  obtenerAlergenosPlatoJSON = (alergenos) => {
    alergenos = JSON.parse(alergenos);
    
    var colAlergenos: string[] = [];

    if(alergenos) {
      //Devolvemos los iconos de los alergenos del plato
      
      for (var aler of alergenos) {
        //Cogemos la etiqueta y el icono
        colAlergenos.push(aler.icon);
      };
    }

    return colAlergenos;
  }

  myFunction(){
    console.log("Pinta la función");
    var popup = document.getElementById("mypopup");
    popup.classList.toggle("show");
  }


  carouselOptions = {
    margin: 0,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsiveClass: true,
    loop:false,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 3,
        nav: true
      },
      1000: {
        items: 4,
        nav: true
      },
      1500: {
        items: 5,
        nav: true
      }
    }
  }

  
  obenerTiposTags(tags, tipo) {
    //Distinguimos los tipos de tags: Normal y Marca
    let tempTags = [];
    let temp = JSON.parse(tags);

    if (temp) {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].tipo == 'marca' && tipo == 'marca') {
          tempTags.push(temp[i]);
        } 

        if (temp[i].tipo == 'normal' && tipo == 'normal') {
          tempTags.push(temp[i]);
        }
      }
    }
    return tempTags;
  }

  cambiarVistaSeccion(seccionId:any){
    if(this.visibilidadSecciones[seccionId]){
      this.visibilidadSecciones[seccionId]=false;
    }else{
      this.visibilidadSecciones[seccionId]=true;
    }
  }

  goDetailPlato(idTipo, item) {
    
    switch(item) {
      case 'carta': {
        if(this.cartaQR) {
          this.router.navigate(['platoQR/'+ idTipo]);
        } else {
          this.router.navigate(['plato/'+ idTipo]);
        }
           break;}
      case 'productos': {this.router.navigate(['producto/'+ idTipo]); break;}
      case 'restaurantes': {this.router.navigate(['restaurante/'+ idTipo]); break;}
      case 'usuarios': {this.router.navigate(['plato/'+ idTipo]); break;} 
    }
  }

  onClickLike(){
    this.fService.setSeguidorRestaurante(this.restaurante.id, this.userLogin.id).subscribe(result => {
      this.resultInsertFollow = result;

      if(this.resultInsertFollow.result=="ok"){
        this.follow=true;
        $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Siguiendo');
        $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
      }
    });
}
}

