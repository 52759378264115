import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { Fabricante } from 'src/app/models/fabricante.model';
import { Publicacion } from 'src/app/models/publicacion.model';
import { PublicacionService } from 'src/app/services/publicacion.service';
import { FollowService } from 'src/app/services/follow.service';
import { GLOBAL } from '../../services/global';

@Component({
  selector: 'app-pagina-usuario',
  templateUrl: './pagina-usuario.component.html',
  styleUrls: ['./pagina-usuario.component.css']
})
export class PaginaUsuarioComponent implements OnInit {
  user: User;
  publicacion: Publicacion
  arrayBotones = [];
  urlResources = GLOBAL.urlResourcesCarta;
  urlIcons = GLOBAL.urlIcons;
  url = "";
  closeResult: string;
  tagsMarca = [];
  tagsNormal = [];
  alergenos = [];
  newTagNormal: string;
  listTagNormal = [];
  tempMarca: any[];
  tempNormal: any[];
  follows: string;
  userLogin:any;
  idUser:number;
  idPublicacion:number;
  resultInsertFollow: any;

  @ViewChild("btnLike", null) btnLike: {nativeElement: any;}

  constructor(
    private route: ActivatedRoute,
    private pService: PublicacionService,
    private fService: FollowService,
  ) { 
    this.user = new User;
    this.publicacion = new Publicacion;
  }

  ngOnInit() {
    this.userLogin=JSON.parse(localStorage.getItem("user"));

    this.url = GLOBAL.url;
    this.route.params.subscribe(result => {
      this.idPublicacion=result.id;
      this.getInfoPublicacion(result.id);     
    });
  }

  getInfoPublicacion(id) {
    this.pService.getInfoPublicacion(id).subscribe(result => {
      console.log('publicacion',result);
      this.publicacion = result;
      
      this.user.id = JSON.parse(result.id_usuario);
      this.user.foto_perfil = result.user.foto_perfil;
      this.user.nombre = result.user.name;
      // Botones del menú principal
      this.arrayBotones = GLOBAL.arrayBotonesUser(this.user.id);

      //Nos traemos los seguidores que tiene este plato
      this.fService.getTotalSeguidoresUsuario(id).subscribe(result2 => {
        this.follows = result2;
      });
  
      this.fService.setSeguidorUsuario(this.idPublicacion, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;
  
        if(this.resultInsertFollow.result=="exists"){
          $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
          $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
        }
      });
  
    });
  }

}
