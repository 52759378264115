import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Plato } from 'src/app/models/plato';
import { Restaurante } from 'src/app/models/restaurante';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PlatosService } from 'src/app/services/platos.service';
import { ProductoService } from 'src/app/services/producto.service';
import { FollowService } from 'src/app/services/follow.service';
import { GLOBAL } from '../../services/global';
import { Tags } from 'src/app/models/tags';

@Component({
  selector: 'app-pagina-plato',
  templateUrl: './pagina-plato.component.html',
  styleUrls: ['./pagina-plato.component.css']
})
export class PaginaPlatoComponent implements OnInit {
  arrayBotones = [];
  plato: Plato;
  restaurante: Restaurante;
  urlResources = GLOBAL.urlResourcesCarta;
  urlIcons = GLOBAL.urlIcons;
  url = "";
  closeResult: string;
  tagsMarca = [];
  tagsNormal = [];
  alergenos = [];
  newTagNormal: string;
  listTagNormal = [];
  tempMarca: any[];
  tempRelFabMarca: [string, number];
  tempNormal: any[];
  follows: string;
  fotoPortada: string;
  nombreRest: string;
  direccionRest: string;
  userLogin:any;
  idPlato:number;
  idProducto:number;
  resultInsertFollow: any;
  cartaQR:boolean=false;

  @ViewChild("btnLike", null) btnLike: {nativeElement: any;}

  constructor(
    private route: ActivatedRoute,
    private pService: PlatosService,
    private prService: ProductoService,
    private fService: FollowService,
    private modalService: NgbModal
  ) {
    this.plato = new Plato;
    this.restaurante = new Restaurante;
  }

  ngOnInit() {
    this.userLogin=JSON.parse(localStorage.getItem("user"));
    this.url = GLOBAL.url;
    this.fotoPortada =  '';
    this.nombreRest =  '';
    this.direccionRest =  '';
    this.route.params.subscribe(result => {
      this.idPlato=result.id;
      this.getInfoPlato(result.id);     
    });
    //Comprobamos el botón del like

  }

  onClickLike(){
      this.fService.setSeguidorPlato(this.idPlato, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if(this.resultInsertFollow.result=="ok"){
          $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
          $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
        }
      });
  }

  // OBTENER LISTADO DE PLATOS DESDE LARAVEL
  getInfoPlato(id) {
    this.pService.getInfoPlato(id).subscribe(result => {
      this.plato = result;
      this.fotoPortada =  result.restaurante.fotoPortada;
      this.nombreRest =  result.restaurante.nombre;
      this.direccionRest  =  result.restaurante.direccion;
      this.plato.id_restaurante = JSON.parse(result.id_restaurante);
      this.plato.descripcion = result.descripcion ? JSON.parse(result.descripcion)[0].data : '';
      this.tempMarca = [];
      this.tempNormal = [];
      let temp = result.tags ? JSON.parse(result.tags) : 0;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].tipo == 'marca') {
          this.prService.getIdFabrProdHashtag(temp[i]).subscribe(result => {
            this.idProducto = result;
            this.tempRelFabMarca = [temp[i].label, this.idProducto];
            this.tempMarca.push(this.tempRelFabMarca);
          });

        } else {
          this.tempNormal.push(temp[i]);
        }
      }

      this.plato.tagsMarca = this.tempMarca;
      this.plato.tagsNormal = this.tempNormal;
      this.plato.alergenos = JSON.parse(result.alergenos);
      
      //Se inicializa la variable restaurante
      this.restaurante.id = this.plato.id_restaurante;
      // Botones del menú principal
      this.arrayBotones = GLOBAL.arrayBotones(this.plato.id_restaurante);
      let currentUrl=window.location.href;
    
      if(currentUrl.indexOf("QR")!=-1){
        this.cartaQR=true;
        this.arrayBotones = GLOBAL.arrayBotonesQR(this.restaurante.id);
      }
      //Nos traemos los seguidores que tiene este plato
      this.fService.getTotalSeguidoresPlatos(id).subscribe(result2 => {
        this.follows = result2;
      });
      if(this.userLogin != null) {
        this.fService.setSeguidorPlato(this.idPlato, this.userLogin.id).subscribe(result => {
          this.resultInsertFollow = result;

          if(this.resultInsertFollow.result=="exists" && this.btnLike){
            $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
            $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
          }
        });
      }
    });
  }

  // ABRIR | CERRAR MODAL EDICIÓN INFO
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    
      if(this.listTagNormal) {
        for (let i = 0; i < this.listTagNormal.length; i++) {
          this.plato.tagsNormal.push(this.listTagNormal[i]);
        }
      }
      // ENVIAR INFO PLATO A LARAVEL
      this.pService.editInfoPlato(this.plato).subscribe(result => {
      });
      this.tempNormal = [];
      this.tempMarca = [];
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // AÑADIR NUEVO TAG NORMAL 
  addNuevoTag(tag) {
    let t = new Tags;
    t.tipo = 'normal';
    t.label = tag;
    this.listTagNormal.push(t);
    this.newTagNormal = '';
  }
}
