import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  headers: {
    Accept: string; "Content-Type": string; //,
  };

  constructor(
    private http: HttpClient
  ) {
    this.headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
  }

  login(params: any) {
    return this.http.post(GLOBAL.urlApi + 'user/login', params, { headers: new HttpHeaders(this.headers) });
  }

  registrarse(params: any) {
    return this.http.post(GLOBAL.urlApi + 'user/registro', params, { headers: new HttpHeaders(this.headers) });
  }
}
