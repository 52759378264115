import { InfoRestauranteComponent } from './components/info-restaurante/info-restaurante.component';
import { InfoFabricanteComponent } from './components/info-fabricante/info-fabricante.component';
import { PaginaPlatoComponent } from './components/pagina-plato/pagina-plato.component';
import { PaginaProductoComponent } from './components/pagina-producto/pagina-producto.component';
import { PaginaBarComponent } from './components/pagina-bar/pagina-bar.component';
import { PaginaFabricanteComponent } from './components/pagina-fabricante/pagina-fabricante.component';
import { PaginaUsuarioComponent } from './components/pagina-usuario/pagina-usuario.component';
import { InfoUsuarioComponent } from './components/info-usuario/info-usuario.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MapaComponent } from './components/mapa/mapa.component';
import { MapaMarcaComponent } from './components/mapa-marca/mapa-marca.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { SeccionCategoriasComponent } from './components/seccion-categorias/seccion-categorias.component';
import { SliderRangeComponent } from './components/slider-range/slider-range.component';
import { ReservasComponent } from './components/reservas/reservas.component';
import { LoginComponent } from './components/login/login.component';
import { RegistrarseComponent } from './components/registrarse/registrarse.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { EditarPerfilComponent } from './components/editar-perfil/editar-perfil.component';
import { IsLoginGuard } from './guards/is-login.guard';
import { PublicacionComponent } from './components/publicacion/publicacion.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'buscador',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'registrarse',
    component: RegistrarseComponent
  },
  {
    path: 'mapa',
    component: MapaComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'mapa-marca/:marca',
    component: MapaMarcaComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'perfil',
    component: PerfilComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'editar-perfil',
    component: EditarPerfilComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'buscador',
    component: BuscadorComponent
  },
  {
    path: 'infobox',
    component: InfoBoxComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'seccion-categorias',
    component: SeccionCategoriasComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'slider-range',
    component: SliderRangeComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'restaurante/:id',
    component: PaginaBarComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'restaurante/:id/info',
    component: InfoRestauranteComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'restaurante/:id/cartaQR',
    component: PaginaBarComponent
  },
  {
    path: 'restauranteQR/:id',
    component: PaginaBarComponent,
  },
  {
    path: 'restauranteQR/:id/info',
    component: InfoRestauranteComponent,
  },
  {
    path: 'platoQR/:id',
    component: PaginaPlatoComponent,
  },
  {
    path: 'fabricante/:id',
    component: PaginaFabricanteComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'fabricante/:id/info',
    component: InfoFabricanteComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'plato/:id',
    component: PaginaPlatoComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'producto/:id',
    component: PaginaProductoComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'usuario/:id',
    component: InfoUsuarioComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'publicacion/:id',
    component: PaginaUsuarioComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'restaurante/:id/reserva',
    component: ReservasComponent,
    canActivate:[IsLoginGuard]
  },
  {
    path: 'publicacion',
    component: PublicacionComponent,
    canActivate:[IsLoginGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
