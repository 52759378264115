import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  headers: {
    Accept: string; "Content-Type": string; //,
  };

  constructor(
    private http: HttpClient
  ) {
    this.headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
  }

  getUserPerfil(user_id: any): Observable<any> {
    return this.http.get(GLOBAL.urlApi + 'perfil/'+user_id, { headers: new HttpHeaders(this.headers) });
  }

  getNumUserSeguidores(user_id: any): Observable<any> {
    return this.http.post(GLOBAL.urlApi + 'perfil/' + user_id + '/numSeguidores', { headers: new HttpHeaders(this.headers) });
  }

  getNumUserSiguiendo(user_id: any): Observable<any> {
    return this.http.post(GLOBAL.urlApi + 'perfil/' + user_id + '/numSiguiendo', { headers: new HttpHeaders(this.headers) });
  }

  getNumPublicaciones(user_id: any): Observable<any> {
    return this.http.post(GLOBAL.urlApi + 'perfil/' + user_id + '/numPublicaciones', { headers: new HttpHeaders(this.headers) });
  }

  getPublicaciones(user_id: any): Observable<any> {
    return this.http.post(GLOBAL.urlApi + 'perfil/' + user_id + '/totalPublicaciones', { headers: new HttpHeaders(this.headers) });
  }

  uploadImageUser(user_id:number,base64:string): Observable<any> {
    const json = JSON.stringify({user_id:user_id,base64:base64});
    const params = json;
    return this.http.post(GLOBAL.urlApi + 'perfil/image', params, { headers:new HttpHeaders(this.headers)  });
  }

  editarPerfil(params){
    return this.http.post(GLOBAL.urlApi + 'perfil/save', params, { headers: new HttpHeaders(this.headers) });
  }
}
