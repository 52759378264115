import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FabricanteService } from 'src/app/services/fabricante.service';
import { Fabricante } from 'src/app/models/fabricante.model';
import { GLOBAL } from '../../services/global';
import { FollowService } from 'src/app/services/follow.service';

interface Fotos {
  image: string;
  thumbImage: string;
  title: string;
}

@Component({
  selector: 'app-pagina-fabricante',
  templateUrl: './pagina-fabricante.component.html',
  styleUrls: ['./pagina-fabricante.component.css']
})
export class PaginaFabricanteComponent implements OnInit {
  @ViewChild('imgQR', { static: true }) imgQR: ElementRef;

  fabricante: Fabricante;
  arrayBotones = [];
  url="";
  secciones = [];
  closeResult: string;
  nuevaSeccion: any;
  selectedFile: File = null;
  imageSrc: any;
  filesToUpload: any;
  file: any;
  textImg: any;
  textPrecio: string;
  urlCarta: string;
  qrOk: boolean = false;
  tempGuid: string;
  arrayCarta: any;
  cartaOk: boolean = false;
  tempSeccion: any;
  admin: boolean;
  visibilidadSecciones:Array<any>=[];
  follows: string;
  userLogin:any;
  resultInsertFollow: any;

  @ViewChild("btnLike", null) btnLike: {nativeElement: any;}

  constructor(
    private route: ActivatedRoute,
    private fService: FabricanteService,
    private router: Router,
    private filService: FollowService,
    /* private modalService: NgbModal,
    private pService: PlatosService */
  ) { 
    this.fabricante = new Fabricante;
  }

  ngOnInit() {
    this.userLogin=JSON.parse(localStorage.getItem("user"));
    this.url=GLOBAL.url;
    this.admin = false;
    this.route.params.subscribe(result => {
      this.fabricante.id = result.id;
      this.arrayBotones = GLOBAL.arrayBotonesFabri(result.id);
      
    });

    // OBTENER DATOS DEL RESTAURANTE
    this.fService.getInfoFabricante(this.fabricante.id).subscribe(result => {
      console.log('result>', result);
      this.fabricante = result;
      this.generarImagenesSecciones(this.fabricante);
      this.generarArrayVisibilidad();
      this.filService.getTotalSeguidoresFabricante(this.fabricante.id).subscribe(result2 => {
        this.follows = result2;
      });
      this.filService.setSeguidorFabricante(this.fabricante.id, this.userLogin.id).subscribe(result => {
        this.resultInsertFollow = result;

        if(this.resultInsertFollow.result=="exists"){
          $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
          $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
        }
      });

    });
  }

  onClickLike(){
    this.filService.setSeguidorFabricante(this.fabricante.id, this.userLogin.id).subscribe(result => {
      this.resultInsertFollow = result;

      if(this.resultInsertFollow.result=="ok"){
        $(this.btnLike.nativeElement).html('<i class="fa fa-heart" aria-hidden="true"></i> Te gusta');
        $(this.btnLike.nativeElement).children('.fa-heart').addClass('animate-like');
      }
    });
}

  generarArrayVisibilidad(){
    for(let seccion of this.fabricante.secciones){
      this.visibilidadSecciones[(<any>seccion).id]=false;
    }
  }

  generarImagenesSecciones=(fabricante)=>{
    console.log('result>', fabricante);
    for(let i=0; i<(<any>this.fabricante).secciones.length;i++){
      let fotosSeccion=[];
      for(let z=0;z<(<any>this.fabricante).secciones[i].productos_secciones.length;z++){
        fotosSeccion.push({thumbImage:GLOBAL.url+(<any>this.fabricante).secciones[i].productos_secciones[z].producto.img
            , descripcion:(<any>this.fabricante).secciones[i].productos_secciones[z].producto.descripcion
            , alergenos:(<any>this.fabricante).secciones[i].productos_secciones[z].producto.alergenos
            , nombreProducto:(<any>this.fabricante).secciones[i].productos_secciones[z].producto.nombre
            , precioProducto:(<any>this.fabricante).secciones[i].productos_secciones[z].producto.precio_producto
            , hastagProductoMarca:(<any>this.fabricante).secciones[i].productos_secciones[z].producto.hashtag
            , guidProducto:(<any>this.fabricante).secciones[i].productos_secciones[z].producto.guid
            , idProducto:(<any>this.fabricante).secciones[i].productos_secciones[z].producto.id
        });
      }
      (<any>this.fabricante).secciones[i].fotoProductos=fotosSeccion;
    }
  }

  imagenPerfilError=(event)=>{
    event.target.src = GLOBAL.url+"no-photo-available.png";
  }

  // CLICK EN IMAGEN
  imageClick(event) {
    console.log("ir al plato");
  }

  obtenerDescripcionPlatoJSON = (cadena) => {
    //Devolvemos el valor del JSON que recibimos
    return cadena == null ? '' : JSON.parse(cadena)[0].data;
  }

  obtenerAlergenosPlatoJSON = (alergenos) => {
    alergenos = JSON.parse(alergenos);
    
    var colAlergenos: string[] = [];

    if(alergenos) {
      //Devolvemos los iconos de los alergenos del plato
      
      for (var aler of alergenos) {
        //Cogemos la etiqueta y el icono
        colAlergenos.push(aler.icon);
      };
    }

    return colAlergenos;
  }

  myFunction(){
    console.log("Pinta la función");
    var popup = document.getElementById("mypopup");
    popup.classList.toggle("show");
  }


  carouselOptions = {
    margin: 0,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsiveClass: true,
    loop:false,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 3,
        nav: true
      },
      1000: {
        items: 4,
        nav: true
      },
      1500: {
        items: 5,
        nav: true
      }
    }
  }

  cambiarVistaSeccion(seccionId:any){
    if(this.visibilidadSecciones[seccionId]){
      this.visibilidadSecciones[seccionId]=false;
    }else{
      this.visibilidadSecciones[seccionId]=true;
    }
  }

  goDetailProducto(idTipo, item) {
    
    switch(item) {
      case 'carta': {this.router.navigate(['plato/'+ idTipo]); break;}
      case 'productos': {this.router.navigate(['producto/'+ idTipo]); break;}
      case 'restaurantes': {this.router.navigate(['restaurante/'+ idTipo]); break;}
      case 'usuarios': {this.router.navigate(['publicacion/'+ idTipo]); break;} 
    }
  }

}
