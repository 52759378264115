import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    private http: HttpClient,
  ) { }

  getPhotosUsuarios(searchBar): Observable<any> {
    const json = JSON.stringify(searchBar);
    const params = 'busqueda=' + json;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'publicacion/getFotosUsuarios', params, { headers });
  }
}
