import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { Plato } from '../models/plato';

@Injectable({
  providedIn: 'root'
})
export class PlatosService {

  constructor(
    private http: HttpClient
  ) { }

  // OBTENER INFO DE PLATO DESDE LARAVEL
  getInfoPlato(id): Observable<any> {
    const params = 'id=' + id;

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'plato/show', params, { headers });
  }

  // OBTENER LISTADO DE TAGS DESDE LARAVEL
  getTags(): Observable<any> {
    const params = '';
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'getTags', params, { headers });
  }

  // OBTENER LISTADO DE ALERGENOS DESDE LARAVEL
  getAlergenos(): Observable<any> {
    const params = '';
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'getAlergenos', params, { headers });
  }

  // SEND DICH INFO TO LARAVEL
  editInfoPlato(plato: Plato):Observable<any> {
    const json = JSON.stringify(plato);
    const params = 'dish=' + json;
    console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'editInfoPlato', params, { headers });
  }

  // NEW PLATO
  newPlato(plato: Plato):Observable<any> {
    const json = JSON.stringify(plato);
    const params = 'plato=' + json;
    console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'newPlato', params, { headers });
  }

  getPhotosPlatos(searchBar, filtros): Observable<any> {
    const varSearchBar = JSON.stringify(searchBar);
    const varFiltros = JSON.stringify(filtros);
    const params = 'busqueda=' + varSearchBar + '&filtros=' + varFiltros;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'plato/getFotosPlatos', params, { headers });
  }

  getPlatosByMarca(marca): Observable<any> {
    const marcaParm = JSON.stringify(marca);
    const params = 'marca=' + marcaParm;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'plato/getPlatosByMarca', params, { headers });
  }

  marcaValidaMapa(marca): Observable<any> {
    const marcaParm = JSON.stringify(marca);
    const params = 'marca=' + marcaParm;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'plato/validarMarcaMapa', params, { headers });
  }
}
