import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class FiltrosService {
  /*headers = {
    "Accept": "application/json",
    "Content-Type": "application/json"
  };*/

  constructor(
    private http: HttpClient
  ) { }

  // OBTENER LISTADO DE FILTROS DESDE LARAVEL
  getFiltros(): Observable<any> {
    const params = '';
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    
    return this.http.post(GLOBAL.urlApi+'restaurante/getFiltros', params, { headers });
  }

  aplicarFiltro(filtro: any): Observable<any> {
    const json = JSON.stringify(filtro);
    const params = 'filtro=' + json;
    console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi+'restaurante/applyFiltro', params, { headers });
  }

  aplicarFiltroArray(filtro: any): Observable<any> {
    const json = JSON.stringify(filtro);
    const params = 'filtro=' + json;
    console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi+'restaurante/applyFiltroArray', params, { headers });
  }
}
