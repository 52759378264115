import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Restaurante } from '../models/restaurante';
import { Seccion } from '../models/seccion';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class RestauranteService {

  headers = {
    "Accept": "application/json",
    "Content-Type": "application/json"
  };

  constructor(
    private http: HttpClient
  ) { }

  // OBTENER INFORMACION DE RESTAURANTE
  getInfoRestaurante(id: any): Observable<any> {
    const params = 'id=' + id;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  
    return this.http.post(GLOBAL.urlApi+'restaurante/show', params, { headers });
  }

  //OBTENER LOS RESTAURANTES DE UN USUARIO
  getRestaurantes(id: any):Observable<any> {
    let headersJSON = new HttpHeaders(this.headers);
    return this.http.get(GLOBAL.urlApi+"user/"+id+"/restaurantes",{ headers:headersJSON });
  }

  // EDITAR INFORMACION DE RESTAURANTE
  editInfoRestaurante(restaurante: Restaurante): Observable<any> {
    const json = JSON.stringify(restaurante);
    const params = 'restaurante=' + json;
    console.log('params>', params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/edit_info', params, { headers });
  }

  // OBTENER SECCIONES DESDE LARAVEL
  getSecciones(id: any): Observable<any> {
    const params = 'id=' + id;
    console.log('params>', params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.put(GLOBAL.urlApi + 'restaurante/getSecciones', params, { headers });
  }

  // AÑADIR SECCION NUEVA
  addSeccion(seccion: Seccion): Observable<any> {
    const json = JSON.stringify(seccion);
    const params = 'seccion=' + json;
    console.log('params>', params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/addSeccion', params, { headers });
  }

  // AÑADIR FOTO A SECCION EXISTENTE
  editSeccion(seccion: Seccion): Observable<any> {
    const json = JSON.stringify(seccion);
    const params = 'seccion=' + json;
    console.log('params>', params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/editSeccion', params, { headers });
  }

  // ELIMINAR SECCION
  delSeccion(id: string): Observable<any> {
    const params = 'id=' + id;
    console.log('params>', params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/deleteSeccion', params, { headers });
  }

  delPlatoSeccion(seccion: Seccion): Observable<any> {
    const json = JSON.stringify(seccion);
    const params = 'seccion=' + json;
    console.log('params>', params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/deletePlatoSeccion', params, { headers });
  }

  // GUARDAR CONTENIDO QR
  storeQR(restaurante: any): Observable<any> {
    const json = JSON.stringify(restaurante);
    const params = 'data=' + json;
    console.log('params>', params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/storeQR', params, { headers });
  }

  //Llamada a Restaurante/bounds
  getFiltrosRestaurante(swlat: String, swlng:String, nelat:String, nelng:String, filtros: any) {
    const filtrosL = JSON.stringify(filtros);
    const params = 'swlat=' + swlat + 'swlng=' + swlng + 'nelat=' + swlng + 'nelng' + nelng + 'filtro=' + filtrosL;

    console.log(params);
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/bounds', params, {headers});

  }

  getAllRestaurants() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = '';
    return this.http.post(GLOBAL.urlApi + 'restaurante/allRestaurants', params, {headers});
  }

  getAllRestaurantsCarta(searchBar) {
    const json = JSON.stringify(searchBar);
    const params = 'busqueda=' + json;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/allRestaurantsCarta', params, {headers});
  }

  getAllRestaurantsProducto(searchBar) {
    const json = JSON.stringify(searchBar);
    const params = 'busqueda=' + json;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/allRestaurantsProducto', params, {headers});
  }

  getAllRestaurantsRestaurante(searchBar) {
    const json = JSON.stringify(searchBar);
    const params = 'busqueda=' + json;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/allRestaurantsRestaurante', params, {headers});
  }

  getAllRestaurantsUsuario(searchBar) {
    
    const json = JSON.stringify(searchBar);
    const params = 'busqueda=' + json;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/allRestaurantsUsuario', params, {headers});
  }

  getPhotosRestaurantes(searchBar): Observable<any> {
    const json = JSON.stringify(searchBar);
    const params = 'busqueda=' + json;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'restaurante/getFotosRestaurantes', params, { headers });
  }
}
