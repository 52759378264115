import { Component, OnInit,ViewChild,ViewContainerRef,ComponentFactoryResolver} from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { DeviceDetectorService } from 'ngx-device-detector';
import {ModalMensajeComponent} from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User;
  refModalMensaje: any;
  isMobile:boolean=false;
  @ViewChild('modalMensaje', { read: ViewContainerRef,static:false }) entry: ViewContainerRef;

  constructor(
    private lService: LoginService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private deviceDetector:DeviceDetectorService
  ) { }

  ngOnInit() {
    this.user = new User();
    this.isMobile=this.deviceDetector.isMobile();
    console.log("isMobile",this.isMobile);
  }

  login() {
    if(this.validarFormLogin()){
      this.lService.login(this.user).subscribe(result => {
        if((<any>result).result=="noExist"){
          this.mostrarMensajeModal("Login","El email introducido es erroneo");
        }else if((<any>result).result=="errorPassword"){
          this.mostrarMensajeModal("Login","La contraseña es erronea");
        }else if((<any>result).result=="ok"){
          localStorage.setItem("user",JSON.stringify((<any>result).user));
          this.router.navigateByUrl('buscador');
        }
      });
    }
    
  }

  irRegistrarse=()=>{
    this.router.navigateByUrl('registrarse');
  }

  validarFormLogin(){
    let camposValidar=["email","password"];
    let nombreCampos={email:"Email",password:"Contraseña"};
    let mensaje="Estos campos son obligatorios: ";
    let error=false;

    for(let i=0;i<camposValidar.length;i++){
      if(this.user[camposValidar[i]]==undefined || this.user[camposValidar[i]]=="" || this.user[camposValidar[i]]==null){
        mensaje+=nombreCampos[camposValidar[i]]+",";
        error=true;
      }
    }

    if(!error){
      return true;
    }else{
      mensaje=mensaje.substring(0,(mensaje.length-1));
      this.mostrarMensajeModal("Login",mensaje);
      return false;
    }
  }

  mostrarMensajeModal(titulo,mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }
}
