import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapaService { 

  private messageSource = new BehaviorSubject([])
  currentMessage = this.messageSource.asObservable();


  private filtros = new BehaviorSubject({})
  currentFiltros = this.filtros.asObservable();

  constructor() {}

  /*getNumMarcadores() {
    return this.marcadores;
  }
  
  async setNumMarcadores(marcadores: number) {
    this.marcadores = marcadores;
  }*/

  changeMessage(marcadores: any) {
    this.messageSource.next(marcadores)
  }

  changeFiltros(filtros: {}){

    this.filtros.next(filtros);
    console.log("next");
    
  }
}
