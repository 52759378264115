import { Alergeno } from './alergeno';
import { Tags } from './tags';

export class Plato {
    guid: string;
    nombre_plato: string;
    precio_plato: string;
    img: string;
    descripcion: string;
    id_restaurante: number;
    tagsMarca: Tags[] = [];
    tagsNormal: Tags[] = [];
    alergenos: Alergeno[] = [];
}
