import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GLOBAL } from '../../../services/global';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  
  isMobile:boolean=false;
  user:any;
  
  @Output() vShowMap = new EventEmitter<string>();
  @Output() vShowPhotos = new EventEmitter<string>();
  @Output() vShowCard = new EventEmitter<string>();
  @Output() vShowFilter = new EventEmitter<string>();
  @Output() vShowSort = new EventEmitter<string>();

  constructor(private deviceDetector:DeviceDetectorService) { }

  ngOnInit() {
    this.isMobile=this.deviceDetector.isMobile();
    this.user=JSON.parse(localStorage.getItem("user"));
  }

  showGoPhoto(){
    this.vShowPhotos.emit();
  }

  showGoMap(){
    this.vShowMap.emit();
  }

  showGoCard(){
    this.vShowCard.emit();
  }

  showGoFilter(){
    this.vShowFilter.emit();
  }

  showGoSort(){
    this.vShowSort.emit();
  }

}
