export class Reserva {
    'fecha': string;
    'hora': string;
    'nMesa': string;
    'ubicacion': string;
    'comensales': string;
    'email': string;
    'tlf': string;
    'contacto': string;
    'rest': string;
    'id_rest': string;
    'tempId': string;
}
