import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PerfilService } from 'src/app/services/perfil.service';
import { GLOBAL } from 'src/app/services/global';
import {ModalMensajeComponent} from 'src/app/components/general/modal-mensaje/modal-mensaje.component';

@Component({
  selector: 'app-editar-perfil',
  templateUrl: './editar-perfil.component.html',
  styleUrls: ['./editar-perfil.component.css']
})
export class EditarPerfilComponent implements OnInit {

  url:string;
  user:any;
  isMobile:boolean;
  refModalMensaje: any;

  @ViewChild('modalMensaje', { read: ViewContainerRef,static:false }) entry: ViewContainerRef;

  constructor(private route:ActivatedRoute,
    private deviceDetector:DeviceDetectorService,
    private pService:PerfilService,
    private resolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.isMobile=this.deviceDetector.isMobile();
    this.url=GLOBAL.url;
    this.route.queryParams.subscribe((params:any) => {
      this.user=JSON.parse(params.user);
    });
  }

  cambiarImagen(file){
    this.getBase64(file[0]);
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload =()=> {
      this.pService.uploadImageUser(this.user.id,reader.result as string).subscribe(result => {
        if(result.result=="ok"){
          this.user.foto_perfil=result.image_url;
        }else{
          this.mostrarMensajeModal("Editar Imagen","Se ha producido un error al editar la imagen");
        }
        
      });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  guardar(){
    if(this.validarForm()){
      this.pService.editarPerfil(this.user).subscribe(result => {
        if((<any>result).result=="ok"){
          this.mostrarMensajeModal("Editar Perfil","Se ha guardado los cambios.");
        }else if((<any>result).result=="exist"){
          this.mostrarMensajeModal("Editar Perfil","No se ha guardado los cambios porque el email ya existe.");
        }else{
          this.mostrarMensajeModal("Editar Perfil","Se ha producido un error al editar el perfil.");
        }
        
      });
    }
  }

  validarForm(){
    let camposValidar=["name","email"];
    let nombreCampos={email:"Email",name:"Nombre"};
    let mensaje="Estos campos son obligatorios: ";
    let error=false;

    for(let i=0;i<camposValidar.length;i++){
      if(this.user[camposValidar[i]]==undefined || this.user[camposValidar[i]]=="" || this.user[camposValidar[i]]==null){
        mensaje+=nombreCampos[camposValidar[i]]+",";
        error=true;
      }
    }

    if(!error){
      return true;
    }else{
      mensaje=mensaje.substring(0,(mensaje.length-1));
      this.mostrarMensajeModal("Editar Perfil",mensaje);
      return false;
    }
  }

  mostrarMensajeModal(titulo,mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

}
