import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import * as $ from 'jquery';
import { MapaService } from '../../services/mapa.service';
import { FiltrosService } from 'src/app/services/filtros.service';
import { SeccionResultadosComponent } from '../seccion-resultados/seccion-resultados.component';
import { indexOf } from 'lodash';
import internal from 'assert';

@Component({
  selector: 'app-seccion-categorias',
  templateUrl: './seccion-categorias.component.html',
  styleUrls: ['./seccion-categorias.component.css']
})
export class SeccionCategoriasComponent implements OnInit {
  //@ViewChild('comidas', { static: true }) comidas: MatSelectionList;

  abiertoAhora: string;
  reservaSiNo: string;
  precioMedio: any;
  localesSelected: string[];
  cocinasSelected: string[];
  comidasSelected: string[];
  totalFiltros: String[][] = [];
  countTotalfiltros: number;
  filtros: {};
  temp = [];
  reservas = [];

  _filtros = [];

  @Output() filterSelected = new EventEmitter<String[][]>();

  constructor(
    private mapService: MapaService,
    private fService: FiltrosService,
    private sResultados: SeccionResultadosComponent) {
    this.countTotalfiltros = 0;
    $(function () { });
  }

  ngOnInit() {
    // OBTENER LISTADO DE FILTROS DESDE LARAVEL
    this.fService.getFiltros().subscribe(result => {
      //console.log(result);
      this._filtros = result;
      //console.log("Filtro 0. Etiqueta: " + this._filtros[0].labels);
      for (let i = 0; i < this._filtros.length; i++) {
        this._filtros[i].labels = JSON.parse(this._filtros[i].labels);
        if(this._filtros[i].tag == 'reservas') {
          this.reservas = this._filtros[i].labels;

        }
      }
      //let temp = this._filtros.find(item => item.nombre == "Origen Cocina"); /* Original -> Se queda así o se añade */
      let temp = this._filtros.find(item => item.nombre == "Tipo cocina");
      temp.labels.sort((a, b) => a.label.localeCompare(b.label));
      //temp = this._filtros.find(item => item.nombre == "Tipo Locales"); /* Original -> Se queda así o se añade */
      //temp = this._filtros.find(item => item.nombre == "Local");
      //temp.labels.sort((a, b) => a.label.localeCompare(b.label));
    });

    // this.mapService.currentFiltros.subscribe(filtros => this.filtros = filtros);
    this.filtros = this._filtros;
    this.mapService.currentFiltros.subscribe(filtros => this.filtros = filtros);
    var categorias_component = this;


    categorias_component.filtros['tipo_filtro'] = 'restaurante'

    //PRODUCTOS
    $(document).on("click", "#boton1", function () {
      $("#filtros_locales").hide();
      $("#filtros_productos").show();
      $("#filtros_platos").hide();
      $("#filtros_eventos").hide();

    });
    //LOCALES
    $(document).on("click", "#boton2", function () {

      $("#filtros_locales").show();
      $("#filtros_productos").hide();
      $("#filtros_platos").hide();
      $("#filtros_eventos").hide();

      $("#boton2").addClass("active");
      $("#boton3").removeClass("active");

      $("#buscar_input_platos").val("");

      categorias_component.filtros = {};
      categorias_component.filtros['tipo_filtro'] = 'restaurante'

      categorias_component.mapService.changeFiltros(categorias_component.filtros);
    });
    //PLATOS
    $(document).on("click", "#boton3", function () {

      $("#filtros_locales").hide();
      $("#filtros_productos").hide();
      $("#filtros_platos").show();
      $("#filtros_eventos").hide();
      $("#boton3").addClass("active");
      $("#boton2").removeClass("active");

      $("#buscar_input").val("");

      categorias_component.filtros = {};
      categorias_component.filtros['tipo_filtro'] = 'platos'
      categorias_component.mapService.changeFiltros(categorias_component.filtros);
    });
    //EVENTOS
    $(document).on("click", "#boton4", function () {

      $("#filtros_locales").hide();
      $("#filtros_productos").hide();
      $("#filtros_platos").hide();
      $("#filtros_eventos").show();

    });




    $("#buscar_button").on("click", function () {

      categorias_component.filtros['buscar'] = $("#buscar_input").val();
      categorias_component.mapService.changeFiltros(categorias_component.filtros);
    })


    $("#buscar_button_platos").on("click", function () {

      categorias_component.filtros['buscar'] = $("#buscar_input_platos").val();
      categorias_component.mapService.changeFiltros(categorias_component.filtros);
    })

    $('#buscar_input_platos').keypress(function (e) {
      if (e.which == 13) {
        $('#buscar_button_platos').click();
        return false;
      }
    });

    $('#buscar_input').keypress(function (e) {
      if (e.which == 13) {
        $('#buscar_button').click();
        return false;
      }
    });

  }

  // Función para recoger el valor de los checkboxes #reservaSelect y #abiertoSelect
  onSelection(event, option, value?) {
    switch (option) {
      case 1:
        if (event.option.selected) {
          this.reservaSiNo = event.option.value.label;
          console.log("reserva", this.reservaSiNo);
        }
        break;
      case 2:
        if (event.option.selected) {
          this.abiertoAhora = event.option.value.label;
          console.log("abierto", this.abiertoAhora);
        }
        break;
      case 3:
        console.log(value);
        break;
    }
  }

  // Función para recoger el valor del slider #sliderPrecio
  priceChange(event) {
    console.log(event.value);
  }

  // Función para recoger las opciones elegidas en los desplegables #locales, #cocinas y #comidas
  onGroupsChange(options: MatListOption[], filtro, tag) {
    this.sendGoFilter(options, filtro, tag);
    // console.log(options.map(option => option.value.label), filtro);
    // this.filtros[filtro] = options.map(option => option.value.label);

    if (options[0] != undefined) {
      if (tag !== 'menu_del_dia' && tag !== 'take_away' && tag !== 'reservas') {
        if(this.temp.indexOf(tag) === -1) {
          this.temp.push(tag);
        }
        options.forEach(element => {
          //console.log(element.getLabel().replace(/(^\s+|\s+$)/g, ''));
          if (this.temp.indexOf(element.getLabel().replace(/(^\s+|\s+$)/g, '')) === -1) {
            this.temp.push(element.getLabel().replace(/(^\s+|\s+$)/g, ''));
          }
        });
        this.sResultados.applyFiltro(filtro, options[0].getLabel().replace(/(^\s+|\s+$)/g, ''), tag, this.temp);
      } else {
        this.sResultados.applyFiltro(filtro, options[0].getLabel().replace(/(^\s+|\s+$)/g, ''), tag);
        this.temp = [];
      }
    } else {
      this.sResultados.applyFiltro(filtro, null, null);
      this.temp = [];
    }
  }

  onNgModelChange(event: any, nombre: string, tag: string) {
    console.log('seccion-categoria', event[0] + '-' + nombre);
    this.sResultados.applyFiltro(event[0], nombre, tag);
  }

  sendGoFilter(value: MatListOption[], filtro: any, tag: any){
    console.log("Total value: " + value.length);
    console.log("Value: ");
    console.log(value);
    console.log(this.totalFiltros.length, this.totalFiltros);

    /*var elementos = [];

    elementos.push(value[0].getLabel().replace(/(^\s+|\s+$)/g, ''));
    elementos.push(filtro);
    elementos.push(tag);    

    this.totalFiltros.push(elementos);*/

    var estaValor = 0;
    var estaFiltro = 0;
    var estaTag = 0;
    var valorLabel = '';


    for(let i=0;i < value.length; i++) {
      valorLabel = value[i].getLabel().replace(/(^\s+|\s+$)/g, '');
      var elementos: String [] = [];
      
      elementos.push(valorLabel);
      elementos.push(filtro);
      elementos.push(tag);
      console.log(i);
      if(this.totalFiltros.length > 0) {
        console.log("Entra en las sucesivas");
        for(let j=0;j< this.countTotalfiltros + 1; j++) {
          console.log(j);
          estaValor = this.totalFiltros[j].findIndex(obj => obj[0] == valorLabel);
          estaFiltro = this.totalFiltros[j].findIndex(obj => obj[1] == filtro);
          estaTag = this.totalFiltros[j].findIndex(obj => obj[2] == tag);
  
          if((estaValor == -1 && estaFiltro == -1 && estaTag == -1) || 
            (estaValor == -1 && estaFiltro == 0 && estaTag == 0) || 
            (estaValor == 0 && estaFiltro == -1 && estaTag == -1)) {
            this.totalFiltros[this.countTotalfiltros] = []
            this.totalFiltros[this.countTotalfiltros].push(elementos[0]);
            this.totalFiltros[this.countTotalfiltros].push(elementos[1]);
            this.totalFiltros[this.countTotalfiltros].push(elementos[2]);
            this.countTotalfiltros++;
            break;
          }
  
        } 
      } else {
        console.log("Entra en la primera pasada");
        this.totalFiltros[this.countTotalfiltros] = [];
        this.totalFiltros[this.countTotalfiltros].push(elementos[0]);
        this.totalFiltros[this.countTotalfiltros].push(elementos[1]);
        this.totalFiltros[this.countTotalfiltros].push(elementos[2]);

        this.countTotalfiltros ++;
        
      }

      console.log(this.totalFiltros);
    
    
    }
    
    this.filterSelected.emit(this.totalFiltros);
  }
}