import { Component, OnInit,ViewChild,ViewContainerRef,ComponentFactoryResolver} from '@angular/core';
import { Router } from '@angular/router';
import {ModalMensajeComponent} from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { User } from 'src/app/models/user';
import { LoginService } from 'src/app/services/login.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.component.html',
  styleUrls: ['./registrarse.component.css']
})
export class RegistrarseComponent implements OnInit {

    user:User;
    repetirPassword:string;
    refModalMensaje: any;
    isMobile:boolean;

    @ViewChild('modalMensaje', { read: ViewContainerRef,static:false }) entry: ViewContainerRef;

  constructor(
    private lService: LoginService,
    private router: Router,
    private resolver: ComponentFactoryResolver,
    private deviceDetector:DeviceDetectorService
  ) { }

  ngOnInit() {
    this.user=new User();
    this.isMobile=this.deviceDetector.isMobile();
  }

  irIniciarSesion=()=>{
    this.router.navigateByUrl('login');
  }

  registrarse=()=>{
    if(this.validarFormRegistro()){
      this.lService.registrarse(this.user).subscribe(result => {

        if((<any>result).result=="exist"){
          this.mostrarMensajeModal("Registrarse","Ya existe un usuario con ese email.");
        }else if((<any>result).result=="ok"){
          localStorage.setItem("user",JSON.stringify((<any>result).user));
          this.router.navigateByUrl('buscador');
        }else if((<any>result).result=="error"){
          this.mostrarMensajeModal("Registrarse","Se ha producido un error inesperado.");
        }
      });
    }
  }

  validarFormRegistro(){
    let camposValidar=["nombre","email","password","repetir_contraseña"];
    let nombreCampos={email:"Email",password:"Contraseña",nombre:"Nombre"};
    let mensaje="Estos campos son obligatorios: ";
    let error=false;

    for(let i=0;i<camposValidar.length;i++){
      if(camposValidar[i]=="repetir_contraseña"){
        if(this.user.password!=this.repetirPassword){
          mensaje="Las Contraseñas no coinciden";
          this.mostrarMensajeModal("Registrarse",mensaje);
          return false;
        }
      }else{
        if(this.user[camposValidar[i]]==undefined || this.user[camposValidar[i]]=="" || this.user[camposValidar[i]]==null){
          mensaje+=nombreCampos[camposValidar[i]]+",";
          error=true;
        }
      }
     
    }

    if(!error){
      return true;
    }else{
      mensaje=mensaje.substring(0,(mensaje.length-1));
      this.mostrarMensajeModal("Registrarse",mensaje);
      return false;
    }
  }

  mostrarMensajeModal(titulo,mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }

  
}
