import { SeccionResultadosComponent } from './../seccion-resultados/seccion-resultados.component';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AgmMap } from '@agm/core';
import { GLOBAL } from '../../services/global';
import * as $ from 'jquery';
import { MapaService } from '../../services/mapa.service';
import { RestauranteService } from 'src/app/services/restaurante.service';
// import { InfoBoxComponent } from '../info-box/info-box.component';
import { Lista_resultados } from 'src/lista_resultados';

declare var google: any;

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css'],
  providers:[SeccionResultadosComponent ],
})

export class MapaComponent implements OnInit {
  @ViewChild('map', { static: true }) map: AgmMap;

  public marcadores=[];
  url = "";
  // Propiedades mapa
  public lat = 38.88454;
  public lng = -4.431721;
  zoom = 18;
  public lista_resultados_ts=[];
  public filtros :{};

  @Input() item = ''; 

  constructor(
      private mapService: MapaService,
      private comp:SeccionResultadosComponent) {
      //this.comp.marcadores = ['1','2']
      //console.log(this.comp.marcadores);
      
     
    }
  
   
  ngOnInit() {
    this.url = GLOBAL.url;
    var mapa_component = this;
    var map;
    var markers = [];

    this.mapService.currentMessage.subscribe(marcadores => this.marcadores = marcadores);

    /*function updateMap(filtros){
      
      mapa_component.filtros = filtros;
      google.maps.event.trigger(map,'idle');
    }

    this.mapService.currentFiltros.subscribe(filtros => updateMap(filtros),error=>console.log("error subscribe filtros"));*/
    // var test23 = this.comp.return_lista('tester');
  //  console.log(this.comp);
    // Array de marcadores
    /*var marcadores = [
      {
        lat: 36.708612,
        lng: -4.431953
      },
      {
        lat: 36.708640,
        lng: -4.431240
      },
      {
        lat: 36.707887,
        lng: -4.432139
      },
      {
        lat: 36.705695,
        lng: -4.436236
      },
      {
        lat: 36.709673,
        lng: -4.440797
      }
    ];*/

    

    // setInterval(function(){
    //   google.maps.event.trigger(map,'idle');
     
    //   console.log( google.maps.event);
    // },5000)
 
   
    // Función inicializar mapa
    
    
    this.initMap(map, mapa_component, markers);
    // Función obtener coordenadas a partir de dirección
    /*function geocodeAddress(geocoder, resultsMap) {
      var address = (<HTMLInputElement>document.getElementById('address')).value;

      geocoder.geocode({ 'address': address }, function (results, status) {

        if (status === 'OK') {
          resultsMap.setCenter(results[0].geometry.location);
          var marker = new google.maps.Marker({
            map: resultsMap,
            position: results[0].geometry.location
          });

        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    }*/

    // Función para obtener mi localización y centrar el mapa en ella
     function centrarMapa() {
       if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(function (position) {
           var pos = {
             lat: position.coords.latitude,
             lng: position.coords.longitude
           };
           map.setCenter(pos);
         });
       } else {
         alert("Browser doesn't support Geolocation");
       }
     }

 
    
    // Función mostrar infoBox
    /*function infoWindow() {
      
      for (let i = 0; i < marcadores.length; i++) {
        var infowindow = new google.maps.InfoWindow();

        google.maps.event.addListener(markers[i], 'click', (function(i) {
          return function() {
            infowindow.setContent('src/app/componentes/info-box/info-box.component.html');
            infowindow.setOptions({maxWidth: 200});
            infowindow.open(map, markers[i]);
            map.setCenter({
              lat: marcadores[i].lat,
              lng: marcadores[i].lng
            });
          }
        }) (i));
      }
    }*/


    // Primera consulta lanzada al iniciara el mapa
    // obtenemos los marcadores que estén dentro del bound de la sección de mapa que estemos visualizando
    
  }

  initMap(map, mapa_component, markers) {
    map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 37.8938454, lng: -4.7831953 },
      zoom: 18,
      streetViewControl: true,
      clickableIcons: false
    });
    //Este evento se lanza cuando cambia el mapa o cuando se pincha en algún sitio
    google.maps.event.addListener(map, 'idle', function() {
      console.log("Entra en el idle");
      var bounds = map.getBounds();//Limites del mapa
      var swlat = bounds.getSouthWest().lat();
      var swlng = bounds.getSouthWest().lng();
      var nelat = bounds.getNorthEast().lat();
      var nelng = bounds.getNorthEast().lng();
     var array_filtros = mapa_component.filtros;
     
      var url = GLOBAL.urlApi+"restaurante/bounds";

      /*if (array_filtros['tipo_filtro']=='restaurante'){
        url = GLOBAL.urlApi+"restaurante/bounds";
      }else if (array_filtros['tipo_filtro']=='platos'){
        url =  GLOBAL.urlApi+"restaurante/bounds_platos";
      }*/
      //console.log(this.lista_resultados_ts);
      
      /* this.rService.getFiltrosRestaurante(swlat, swlng, nelat, nelng, array_filtros).subscribe(result => {
        console.log("result");
        console.log(result);
      }); */

      $.ajax({
        url: url,
        type: "POST",
        data:{
          swlat:swlat,
          swlng:swlng,
          nelat:nelat,
          nelng:nelng,
          filtros:JSON.stringify(array_filtros)
        },
        dataType: "json",
        success:(data)=>{
          //console.log("DATA:");
          //console.log(data);
          
            this.lista_resultados_ts = data;
            var lista_resultados = [];
            console.log(markers);
            for (var i = 0; i < markers.length; i++) {

              markers[i].setMap(null);
            }
            
            for (let i = 0; i < data.length; i++) {

              if (data[i].id_imagen == null){

                data[i].id_imagen = 1

              }
              var imagen =  GLOBAL.url+'no-photo-available.png';
              
              // Recorremos las secciones del restaurante para obtener las etiquetas de los platos
              var etiquetasPlatos = [];
              

              for(let j = 0; j < data[i].secciones.length; j ++){
                for(let k= 0; k < data[i].secciones[j].platos.length; k++) {
                    //console.log(data[i].secciones[j].platos[k].tags);
                    etiquetasPlatos.push(mapa_component.obenerTiposTags(data[i].secciones[j].platos[k].tags, 'normal'));
                }
              }

              // ¿Cuántas etiquetas se devuelven?
              // Preparar para saber cuántas quieren en el market del mapa
              //console.log(etiquetasPlatos[0][0].label);

              if (!data[i].subnombre){

                data[i].subnombre = "";

              }
              imagen = GLOBAL.url + data[i].fotoPerfil;
              /*if (array_filtros['tipo_filtro']=='restaurante'){
                imagen = GLOBAL.url + data[i].fotoPerfil;
              }else if (array_filtros['tipo_filtro']=='platos'){       
                imagen = GLOBAL.url + data[i].img;
              }
              else {
                //Entra por aquí cuando se pone en la url: http://localhost:4200/mapa
                //console.log(imagen)
              }*/

              

              lista_resultados.push({
                id:data[i].id,
                nombre:data[i].nombre,
                claim:data[i].claim,
                precio_medio_carta:data[i].precio_medio_carta,
                subnombre:data[i].subnombre,
                id_imagen:data[i].id_imagen,
                //tipo_filtro :array_filtros['tipo_filtro'],
                img_url :imagen,
                tags: etiquetasPlatos,
              });
              
              var marker = new google.maps.Marker({
                position: 
                { 
                  lat: parseFloat(data[i].lat), 
                  lng:  parseFloat(data[i].lng) 
                },
                visible: true
              });

              marker.setMap(map);
              markers.push(marker);
              
              // this.lista_resultados_return('test');
              
              
              //console.log(lista_resultados);
              // console.log(mapa_component);
             
              var infowindow = new google.maps.InfoWindow();

            
                  /*infowindow.setContent(data[i].nombre);
                  infowindow.setOptions({ maxWidth: 500 });
                  //infowindow.open(map, marker); 
                  marker.addListener('click', function() {
                    infowindow.open(map, marker);
                  });*/

                  //Cuando se recarga la página, mostrando cada marker
                  google.maps.event.addListener(marker, 'click', (function(marker, i) {
                    return function() {
                      infowindow.setContent('<head><style>'+
                      '.color-naranja {color:#E35100}'+
                      '.fondo-naranja {background-color:#E35100}'+
                      '.map-tags{'+
                        'background-color: #F6F5F5;'+
                        'padding-right: 10px;'+
                          'padding-left: 10px;'+
                          'font-size: 11px;'+
                          'border: 1px solid gainsboro;'+
                        'border-radius: 2px;'+
                        'padding-top: 2px;'+
                          'padding-bottom: 2px;'+
                          'display: inline-block;'+
                          'margin-top: 6px;'+
                      '}'+
                      '.gm-style-iw-d{overflow:hidden !important;}'+
                      '.gm-style-iw.gm-style-iw-c{padding:0px !important;}'+
                      '.resena{'+
                              'padding: 6px;'+
                          'border-radius: 7px;'+
                          'color: white;'+
                          'font-size: 19px;'+
                      '}</style></head><body><div style="line-height: 19px;border: 1px solid gainsboro;border-radius: 6px;width:506px;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;font-size: 14px;" class="row">'+
                      '<div class="col-md-5 col-sm-5" style="padding-left: 0px;z-index: 0;">'+
                          '<img id="restaurante" src="'+imagen+'" onerror="this.onerror=null; this.src=\''+GLOBAL.url+'no-photo-available.png\'" alt="restaurante" style="width: 195px;height: 179px;object-fit: cover;max-height: 100%;max-width: 100%;">'+
                      '</div>'+
                      '<div class="col-md-7 col-sm-7">'+
                          '<div class="row" style="padding-top: 7px;">'+
                              '<div class="col-md-7 col-sm-7">'+
                                  '<span><b><a href="/restaurante/'+data[i].id+'">'+data[i].nombre+'</a></b></span>'+
                              '</div>'+
                              '<div class="col-md-5 col-sm-5">'+
                                '<div class="pull-right">'+
                                    '<i class="fa fa-check-square-o color-naranja" style="margin-right: 2px;"></i>'+
                                    '<i class="fa fa-thumbs-o-up color-naranja" style="margin-right: 2px;"></i>'+
                                    '<i class="fa fa-heart-o color-naranja" style="margin-right: 2px;"></i>'+
                                    '<i class="fa fa-share-square-o color-naranja" style="margin-right: 2px;"></i>'+
                                  '</div>'+
                              '</div>'+
                          '</div>'+
                          '<div class="row">'+
                              '<div class="col-md-12 col-sm-12">'+
                                  '<span class="color-naranja"><b>'+data[i].subnombre+'</b></span>'+
                                 
                              '</div>'+
                          '</div>'+
                          '<div class="row">'+
                              '<div class="col-md-12 col-sm-12">'+
                                  '<i>'+data[i].claim+'</i>'+
                              '</div>'+
                          '</div>'+
                          '<div class="row">'+
                          '<div class="col-md-2 col-sm-2" style="padding-top: 14px;">'+
                                  '<span class="fondo-naranja resena align-middle"><b>8,2</b></span>'+
                              '</div>'+
                              
                          '</div>'+
                          '<div class="row">'+
                              '<div class="col-md-12 col-sm-12">'+
                                  '<a class="pull-left"><u>Precio medio '+data[i].precio_medio_carta+'€</u></a>'+
                                  //<a class="pull-right"><u>Menú del día 12€</u></a>'+
                              '</div>'+
                          '</div>'+
                          '<div class="row" style="margin-top: 10px;">'+
                              '<div class="col-md-5 col-sm-5">'+
                                  '<i class="fa fa-circle" style="color:#28a745"></i> Abierto'+
                              '</div>'+
                              '<div class="col-md-7 col-sm-7" style="padding:0px">'+
                                  '<button class="btn fondo-naranja" style="color:white;padding: 4px;border-radius: 0px;">Reservar</button>'+
                                  '<button class="btn fondo-naranja" style="color:white;padding: 4px;border-radius: 0px;margin-left: 15px;"><a href="/restaurante/'+data[i].id+'" style="color: white;">Ver local</a></button>'+
                              '</div>'+
                          '</div>'+
                      '</div>'+
                  '</div></body>');
                  infowindow.setOptions({ maxWidth: 600 });
                      infowindow.open(map, marker);
                    }
                })(marker, i));

            }
            if (data[0]){
              mapa_component.updateResultados(lista_resultados);
            }else{

              mapa_component.updateResultados([]);

            }
          //console.log(lista_resultados);
          
        },
        error:function(xhr, textStatus, errorThrown){
            console.log(errorThrown,xhr,textStatus);
        }
      });
     
        
     
      
      //console.log(swlng);
      //alert("minimum lat of current map view: " + sw.lat());
     });

    // Inicializamos geocoder
    var geocoder = new google.maps.Geocoder();

    

    // Añadir listener a boton 'centrar'
    /*document.getElementById('center').addEventListener('click', function () {
      centrarMapa();
    });*/

    // añadimos listener para abrir infowindow
    //infoWindow();
  };

  updateResultados(resultados){
   
    this.mapService.changeMessage(resultados);

  }

  obenerTiposTags(tags, tipo) {
    //Distinguimos los tipos de tags: Normal y Marca
    let tempTags = [];
    let temp = JSON.parse(tags);

    if (temp) {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].tipo == 'marca' && tipo == 'marca') {
          tempTags.push(temp[i]);
        } 

        if (temp[i].tipo == 'normal' && tipo == 'normal') {
          tempTags.push(temp[i]);
        }
      }
    }
    return tempTags;
  }


}