import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute,Router} from '@angular/router';
import { PerfilService } from 'src/app/services/perfil.service';
import { GLOBAL } from 'src/app/services/global';
import {ModalMensajeComponent} from 'src/app/components/general/modal-mensaje/modal-mensaje.component';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  isMobile:boolean=false;
  user:any;
  seguidores:number;
  siguiendo:number;
  numPublicaciones:number;
  publicaciones:any;
  userLogin:any;
  url:string;
  refModalMensaje: any;

  @ViewChild('modalMensaje', { read: ViewContainerRef,static:false }) entry: ViewContainerRef;

  constructor(private deviceDetector:DeviceDetectorService,
    private route:ActivatedRoute,
    private router: Router,
    private pService:PerfilService,
    private resolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.isMobile=this.deviceDetector.isMobile();
    this.route.queryParams.subscribe((params) => {
      this.getPerfil(params.user_id);
      this.getNumSeguidores(params.user_id);
      this.getNumSiguiendo(params.user_id);
      this.getNumPublicaciones(params.user_id);
      this.getPublicaciones(params.user_id);
    });

    this.url=GLOBAL.url;

    this.userLogin=JSON.parse(localStorage.getItem("user"));
  }

  getPerfil(userId:number){
    this.pService.getUserPerfil(userId).subscribe(result => {
      if(result.result=="ok"){
        this.user=result.user;
      }else{
        this.mostrarMensajeModal("Perfil","Se ha producido un error al obtener los datos del perfil");
      }
    });
  }

  getNumSeguidores(userId:number){
    this.pService.getNumUserSeguidores(userId).subscribe(result => {
      if(result.result == 'ok'){
        this.seguidores = result.numSeguidores;
      } else {
        this.mostrarMensajeModal("Seguidores","Se ha producido un error al obtener el número de seguidores");
      }
    })
  }

  getNumSiguiendo(userId:number){
    this.pService.getNumUserSiguiendo(userId).subscribe(result => {
      if(result.result == 'ok'){
        this.siguiendo = result.numSiguiendo;
      } else {
        this.mostrarMensajeModal("Seguidos","Se ha producido un error al obtener el número de seguidos");
      }
    })
  }

  getNumPublicaciones(userId:number){
    this.pService.getNumPublicaciones(userId).subscribe(result => {
      if(result.result == 'ok'){
        this.numPublicaciones = result.numPublicaciones;
      } else {
        this.mostrarMensajeModal("Publicaciones","Se ha producido un error al obtener el número de publicaciones");
      }
    })
  }

  getPublicaciones(userId:number){
    this.pService.getPublicaciones(userId).subscribe(result => {
      if(result.result == 'ok'){
        this.publicaciones = result.totalPublicaciones;
        for(let publicacion of this.publicaciones){
          let fecha=new Date(publicacion.created_at);
          publicacion.fechaFormateada=fecha.getDay()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
        }
        console.log(this.publicaciones);
      } else {
        this.mostrarMensajeModal("Publicaciones","Se ha producido un error al obtener el listado de publicaciones");
      }
    })
  }

  cerrarSesion=()=>{
    localStorage.removeItem('user');
    this.router.navigateByUrl('login');
  }

  editarPerfil=()=>{
    this.router.navigate(['editar-perfil'],{queryParams: {user: JSON.stringify(this.user)}});
  }

  reformatDate(dateStr)
  {
    if(dateStr!="" && dateStr!=null){
      let dArr = dateStr.split("-");
      return dArr[2]+ "/" +dArr[1]+ "/" +dArr[0];
    }else{
      return "";
    }
  }

  mostrarMensajeModal(titulo,mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }
  
}
