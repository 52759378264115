import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FollowService {
  headers: {
    Accept: string; "Content-Type": string; //,
  };
  constructor(
    private http: HttpClient
    ) {
      this.headers = {
        "Accept": "application/json",
        "Content-Type": "application/json"
      };
  }
    
  getTotalSeguidoresPlatos(id: number): Observable<any> {
    const params = 'id=' + id;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'follow/getTotalSegPlatos', params, { headers });
  }

  getTotalSeguidoresProductos(id: number): Observable<any> {
    const params = 'id=' + id;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'follow/getTotalSegProductos', params, { headers });
  }

  getTotalSeguidoresRestaurante(id: number): Observable<any> {
    const params = 'id=' + id;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'follow/getTotalSegRest', params, { headers });
  }

  getTotalSeguidoresFabricante(id: number): Observable<any> {
    const params = 'id=' + id;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'follow/getTotalSegFabr', params, { headers });
  }

  getTotalSeguidoresUsuario(id: number): Observable<any> {
    const params = 'id=' + id;
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'follow/getTotalSegUser', params, { headers });
  }

  setSeguidorPlato(id: number, id_user: number): Observable<any> {
    const json = JSON.stringify({id:id,id_user:id_user});
    const params = json;
    const headers = new HttpHeaders(this.headers);
    return this.http.post(GLOBAL.urlApi + 'follow/insertFollowPlato', params, { headers });
  }

  setSeguidorProducto(id: number, id_user: number): Observable<any> {
    const json = JSON.stringify({id:id,id_user:id_user});
    const params = json;
    const headers = new HttpHeaders(this.headers);
    return this.http.post(GLOBAL.urlApi + 'follow/insertFollowProducto', params, { headers });
  }

  setSeguidorRestaurante(id: number, id_user: number): Observable<any> {
    const json = JSON.stringify({id:id,id_user:id_user});
    const params = json;
    const headers = new HttpHeaders(this.headers);
    return this.http.post(GLOBAL.urlApi + 'follow/insertFollowRest', params, { headers });
  }

  setSeguidorFabricante(id: number, id_user: number): Observable<any> {
    const json = JSON.stringify({id:id,id_user:id_user});
    const params = json;
    const headers = new HttpHeaders(this.headers);
    return this.http.post(GLOBAL.urlApi + 'follow/insertFollowFabr', params, { headers });
  }

  setSeguidorUsuario(id: number, id_user: number): Observable<any> {
    const json = JSON.stringify({id:id,id_user:id_user});
    const params = json;
    const headers = new HttpHeaders(this.headers);
    return this.http.post(GLOBAL.urlApi + 'follow/insertFollowUser', params, { headers });
  }
}


