import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FabricanteService } from 'src/app/services/fabricante.service';
import { Fabricante } from 'src/app/models/fabricante.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from '../../services/global';
import { FiltrosService } from 'src/app/services/filtros.service';
import { rest } from 'lodash';

@Component({
  selector: 'app-info-fabricante',
  templateUrl: './info-fabricante.component.html',
  styleUrls: ['./info-fabricante.component.css']
})
export class InfoFabricanteComponent implements OnInit {

  arrayBotones = [];
  arrayFiltros = [];
  id: any;
  fabricante: Fabricante;
  closeResult: string;
  url = "";

  constructor(
    private route: ActivatedRoute,
    private fService: FabricanteService,
    private modalService: NgbModal,
    private fiService: FiltrosService
  ) {
    this.fabricante = new Fabricante();
   }

  ngOnInit() {
    this.url = GLOBAL.url
    this.route.params.subscribe(result => {
      this.arrayBotones = GLOBAL.arrayBotonesFabri(result.id);
      this.id = result.id;
      console.log('id>>', this.id);
    });

    this.getInfoFabricante(this.id);

    // OBTENER LISTADO DE FILTROS
    this.fiService.getFiltros().subscribe(result => {
      this.arrayFiltros = result;
      console.log('filtros>', this.arrayFiltros);
      for (let i = 0; i < this.arrayFiltros.length; i++) {
        this.arrayFiltros[i].labels = JSON.parse(this.arrayFiltros[i].labels)
      }
    });
  }

  getInfoFabricante(id: any) {
    // OBTENER DATOS DEL RESTAURANTE
    this.fService.getInfoFabricante(id).subscribe(result => {
      console.log('fabricante>', result);
      
      this.fabricante = result;
      this.fabricante.secciones = result.secciones;
      this.fabricante.facebook = result.facebook;
      this.fabricante.instagram = result.instagram;
      this.fabricante.youtube = result.youtube;
      this.fabricante.linkedin = result.linkedin;
      this.fabricante.twitter = result.twitter;
    });
  }

}
