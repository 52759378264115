import { Component, OnInit, Injectable, ViewChild, ElementRef, Input } from '@angular/core';
import { MapaService } from '../../../services/mapa.service';
import { GetPhotosService } from "../../../services/get-photos.service";
import { ModalService } from '../../_modal/modal.service';
import { FiltrosService } from 'src/app/services/filtros.service';
import { Filtro } from 'src/app/models/filtro';
import { Restaurante } from 'src/app/models/restaurante';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { PlatosService } from 'src/app/services/platos.service';
import { ProductoService } from 'src/app/services/producto.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { GLOBAL } from '../../../services/global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { MatSelectionList, MatListOption } from '@angular/material/list';

@Component({
  selector: 'app-seccion-carta',
  templateUrl: './seccion-carta.component.html',
  styleUrls: ['./seccion-carta.component.css']
})
export class SeccionCartaComponent implements OnInit {

  url="";
  restaurante: Restaurante;
  platos = [];
  isMobile:boolean=false;
  imgSeccion = [];

  valorFiltro : MatListOption;
  labeFiltro : any;
  tagFiltro : any;

  @Input() item = ''; 
  @Input() searchBar = ''; 
  @Input() filtro : MatListOption[]; 
  
  constructor(
    private deviceDetector:DeviceDetectorService,
    private pService: PlatosService,
    private prService: ProductoService,
    private uService: UsuariosService,
    private rService: RestauranteService,
    private router: Router,) { 
      this.restaurante = new Restaurante;
    }

  ngOnInit() {
    this.url=GLOBAL.url;
    this.isMobile=this.deviceDetector.isMobile();
  }

  @Input() isFirstRun;
  ngOnChanges(changes:any) {
    console.log(changes);
    //console.log(this.searchBar); //No está devolviendo un array con los ids de los hashtags, devolvemos los platos que tengan ese id
    //console.log(this.filtro);
    if(this.filtro) {
      this.filtro.forEach(element => {
        console.log(element);
      });
      this.valorFiltro = this.filtro[0];
      this.labeFiltro = this.filtro[1];
      this.tagFiltro = this.filtro[2];
      //sessionStorage.setItem('filtro', this.filtro);
    }

    //sessionStorage.setItem('filtro', 'Rana Hasnain');
   
    console.log(this.valorFiltro, this.labeFiltro, this.tagFiltro);//Seguir por aquí
    var seccionItem = changes.item ? changes.item.currentValue : this.item;
  
    this.imgSeccion = []

    switch(seccionItem) {
      case 'carta': {this.generarImagenesSeccionesCarta(this.searchBar, this.filtro); break;}
      case 'productos': {this.generarImagenesSeccionesProductos(this.searchBar, this.filtro); break;}
      case 'restaurantes': {this.generarImagenesSeccionesRestaurantes(this.searchBar); break;}
      case 'usuarios': {this.generarImagenesSeccionesUsuarios(this.searchBar); break;}
    }
    
    
  }

  generarImagenesSeccionesCarta=(searchBar, filtros)=>{
    this.pService.getPhotosPlatos(searchBar, filtros).subscribe(result => {
      this.imgSeccion = [];
      for (let valor of Object.values(result)) {
          this.imgSeccion.push({thumbImage:GLOBAL.url+valor['img']
          , guidPlato: valor['guid']
          , idPlato: valor['id']
        });
        /* this.restaurante = valor;
        this.platos.push(this.restaurante);
        for(let i=0; i<(<any>this.restaurante).secciones.length;i++){
          let fotosSeccion=[];
          for(let z=0;z<(<any>this.restaurante).secciones[i].platos.length;z++){
            fotosSeccion.push({
                thumbImage:GLOBAL.url+(<any>this.restaurante).secciones[i].platos[z].img
                , descripcion:(<any>this.restaurante).secciones[i].platos[z].descripcion
                , alergenos:(<any>this.restaurante).secciones[i].platos[z].alergenos
                , nombrePlato:(<any>this.restaurante).secciones[i].platos[z].nombre_plato
                , precioPlato:(<any>this.restaurante).secciones[i].platos[z].precio_plato
                , hastagPlatoMarca:this.obenerTiposTags((<any>this.restaurante).secciones[i].platos[z].tags, 'marca')
                , hastagPlatoNormal:this.obenerTiposTags((<any>this.restaurante).secciones[i].platos[z].tags, 'normal')
                , guidPlato:(<any>this.restaurante).secciones[i].platos[z].guid
                , idPlato:(<any>this.restaurante).secciones[i].platos[z].id
            });
          }
          (<any>this.restaurante).secciones[i].fotoPlatos=fotosSeccion;
        } */
      }
    });
  };

  generarImagenesSeccionesProductos=(searchBar, filtros)=>{
    this.prService.getPhotosProductos(searchBar, filtros).subscribe(result => {
      for (let valor of Object.values(result)) {
          this.imgSeccion.push({thumbImage:GLOBAL.url+valor['img']
          , guidProducto: valor['guid']
          , idProducto: valor['id']
        });
      }
    });
  };

  generarImagenesSeccionesRestaurantes=(searchBar)=>{
    this.rService.getPhotosRestaurantes(searchBar).subscribe(result => {
      for (let valor of Object.values(result)) {
          this.imgSeccion.push({thumbImage:GLOBAL.url+valor['fotoPerfil']
          , guidPlato: valor['guid']
          , idPlato: valor['id']
        });
      }
    });
  };

  generarImagenesSeccionesUsuarios=(searchBar)=>{
    this.uService.getPhotosUsuarios(searchBar).subscribe(result => {
      for (let valor of Object.values(result)) {
          this.imgSeccion.push({thumbImage:GLOBAL.url + valor['img']
          , guidPlato: valor['guid']
          , idPlato: valor['id']
        });
      }
    });
  };

  goDetailPlato(idTipo, item) {
    
    switch(item) {
      case 'carta': {this.router.navigate(['plato/'+ idTipo]); break;}
      case 'productos': {this.router.navigate(['producto/'+ idTipo]); break;}
      case 'restaurantes': {this.router.navigate(['restaurante/'+ idTipo]); break;}
      case 'usuarios': {this.router.navigate(['publicacion/'+ idTipo]); break;} 
    }
  }
}

