import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicacionService {
  headers: {
    Accept: string; "Content-Type": string; //,
  };

  constructor(private http: HttpClient){ 
    this.headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
  }

  uploadImageUser(user_id:number,base64:string): Observable<any> {
    const json = JSON.stringify({user_id:user_id,base64:base64});
    const params = json;
    return this.http.post(GLOBAL.urlApi + 'publicacion/image', params, { headers:new HttpHeaders(this.headers)  });
  }

  addPublicacion(publicacion:any={}, imagePublic:any, user:any): Observable<any> {
    const json = JSON.stringify({publicacion:publicacion,imagePublic:imagePublic, user:user});
    const params = json;
    return this.http.post(GLOBAL.urlApi + 'publicacion/add', params, { headers:new HttpHeaders(this.headers)  });
  }

  getAllHashtagMarca() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = '';
    return this.http.post(GLOBAL.urlApi + 'publicacion/allHashtagsMarca', params, {headers});
  }

  getAllHashtagNormal() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const params = '';
    return this.http.post(GLOBAL.urlApi + 'publicacion/allHashtagsNormal', params, {headers});
  }

  getInfoPublicacion(id): Observable<any> {
    const params = 'id=' + id;

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'publicacion/show', params, { headers });
  }

  getInfoPublicacionUsuario(id): Observable<any> {
    const params = 'id=' + id;

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(GLOBAL.urlApi + 'publicacion/getUser', params, { headers });
  }
}
