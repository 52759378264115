import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';

import { GLOBAL } from 'src/app/services/global';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute} from '@angular/router';
import { PublicacionService } from 'src/app/services/publicacion.service';
import { PerfilService } from 'src/app/services/perfil.service';
import {ModalMensajeComponent} from 'src/app/components/general/modal-mensaje/modal-mensaje.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { Select2OptionData } from 'ng2-select2';
import {SelectModule} from 'ng2-select';

@Component({
  selector: 'app-publicacion',
  templateUrl: './publicacion.component.html',
  styleUrls: ['./publicacion.component.css']
})
export class PublicacionComponent implements OnInit {
  isMobile:boolean=false;
  url:string;
  user:any;
  nombreRest:any = 'restaurante';
  nombreHM:any = 'marca';
  nombreHN:any = 'normal';
  refModalMensaje: any;
  publicacion:any={};
  imagePublic:any;
  public imagePath;
  restaurantes:any = [];
  hashtagsMarca:any = [];
  hashtagsNormal:any = [];
  public optionsHM: Select2Options;
  public optionsHN: Select2Options;
  public optionsRE: Select2Options;

  @ViewChild('modalMensaje', { read: ViewContainerRef,static:false }) entry: ViewContainerRef;

  constructor(private deviceDetector:DeviceDetectorService,
    private route:ActivatedRoute,
    private resolver: ComponentFactoryResolver,
    private pService:PublicacionService,
    private rService:RestauranteService,
    private perfService:PerfilService) { }

  ngOnInit() {
  
    this.isMobile=this.deviceDetector.isMobile();

    this.url=GLOBAL.url;

    this.rService.getAllRestaurants().subscribe(result => {
      this.restaurantes = result;
    });

    this.pService.getAllHashtagMarca().subscribe(result => {
      this.hashtagsMarca = result;
    });

    this.pService.getAllHashtagNormal().subscribe(result => {
      this.hashtagsNormal = result;
    });

    this.optionsHM = {
      multiple: true,
      placeholder: 'Elija hashtag de marca',
      id: this.nombreHM,
    }

    this.optionsHN = {
      multiple: true,
      placeholder: 'Elija hashtag normal',
      id: this.nombreHN,
    }

    this.optionsRE = {
      placeholder: 'Elija un restaurante',
      id: this.nombreRest,
      allowClear: true,//x para quitar selección
      selectOnClose: false,
      closeOnSelect: false,
      dropdownAutoWidth:false,
      multiple: false,
      
    }
    
    this.route.queryParams.subscribe((params:any) => {
      this.user=JSON.parse(params.user_id);
      this.getPerfil(params.user_id);
    });

    this.route.params.subscribe(result => {
      console.log(result);
      
    });
  }

  getPerfil(userId:number){
    this.perfService.getUserPerfil(userId).subscribe(result => {
      if(result.result=="ok"){
        this.user=result.user;
      }else{
        this.mostrarMensajeModal("Perfil","Se ha producido un error al obtener los datos del perfil");
      }
     
    });
  }

  cambiarImagen(file){
    this.getBase64(file[0]);
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload =()=> {
      this.imagePublic = reader.result;

      /*
      this.pService.uploadImageUser(this.user.id,reader.result as string).subscribe(result => {
        if(result.result=="ok"){
          this.user.foto_perfil=result.image_url;
        }else{
          this.mostrarMensajeModal("Editar Imagen","Se ha producido un error al editar la imagen");
        }
        
      });*/
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  guardar(){
    if(this.validarForm()){
      this.pService.addPublicacion(this.publicacion, this.imagePublic, this.user).subscribe(result => {
        if((<any>result).result=="ok"){
          this.mostrarMensajeModal("Añadir publicación","Se ha guardado los cambios.");
        }else if((<any>result).result=="exist"){
          this.mostrarMensajeModal("Añadir publicación","No se ha guardado los cambios porque el email ya existe.");
        }else{
          this.mostrarMensajeModal("Añadir publicación","Se ha producido un error al insertar la publicación.");
        }
        
      });
    }
  }

  validarForm(){
    let camposValidar=["descripcion", "marca", "normal" ,"restaurante"];
    let nombreCampos={descripcion:"Descripción ", restaurante:"Restaurante ", marca:"Hashtah de Marca ", normal:"Hashtag normal "};
    let mensaje="Estos campos son obligatorios: ";
    let mensajeImagen="Debe subir una imagen";
    let errorCampos=false;
    let errorImagen=false;

    for(let i=0;i<camposValidar.length;i++){
      if(this.publicacion[camposValidar[i]]==undefined 
        || this.publicacion[camposValidar[i]]=="" 
        || this.publicacion[camposValidar[i]]==null
        || this.publicacion[camposValidar[i]].length == 0
        ){
        mensaje+=nombreCampos[camposValidar[i]]+",";
        errorCampos=true;
      }

      if(this.imagePublic == undefined 
        || this.imagePublic == ""
        || this.imagePublic == null) {
          mensajeImagen;
          errorImagen=true;
        }
    }

    if(!errorCampos && !errorImagen){
      return true;
    }else{
      if(errorCampos){
        mensaje=mensaje.substring(0,(mensaje.length-1));
        this.mostrarMensajeModal("Añadir publicación",mensaje);
        return false;
      }
      if(errorImagen){
        mensajeImagen=mensajeImagen.substring(0,(mensajeImagen.length-1));
        this.mostrarMensajeModal("Añadir publicación",mensajeImagen);
        return false;
      }
      
    }
  }

  mostrarMensajeModal(titulo, mensaje) {
    this.entry.clear();
    const factory = this.resolver.resolveComponentFactory(ModalMensajeComponent);
    this.refModalMensaje = this.entry.createComponent(factory);
    this.refModalMensaje.instance.mensaje = mensaje;
    this.refModalMensaje.instance.titulo = titulo;
    this.refModalMensaje.changeDetectorRef.detectChanges();
  }


  getInfoProducto(id) {
    this.pService.getInfoPublicacion(id).subscribe(result => {
      console.log('publicacion',result);
      this.publicacion = result;
    });

  }

 

}
