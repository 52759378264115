import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestauranteService } from 'src/app/services/restaurante.service';
import { GLOBAL } from '../../../services/global';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.css']
})
export class HeaderBarComponent implements OnInit {
  
  user:any;
  restaurantes:Array<any>=[];
  isMobile:boolean=false;

  @Input() cartaQR; 

  constructor(private router: Router,
    private rService: RestauranteService,
    private deviceDetector:DeviceDetectorService) {
    
  }

  ngOnInit() {
    this.isMobile=this.deviceDetector.isMobile();
    this.user=JSON.parse(localStorage.getItem("user"));
    if(this.user!=null){
      this.getRestaurantes();
    }
  }

  cerrarSesion=()=>{
    localStorage.removeItem('user');
    this.router.navigateByUrl('login');
  }

  getRestaurantes=()=>{
    this.rService.getRestaurantes(this.user.id).subscribe(result => {
      if(result.result=="ok"){
        this.restaurantes=result.restaurantes;
      }
    });
    
  }

  irAdmin=()=>{
    window.open(GLOBAL.urlAdmin, '_blank');
  }

  irLogin=()=>{
    this.router.navigateByUrl('login');
  }

  irRegistrate=()=>{
    this.router.navigateByUrl('registrarse');
  }

  irPerfil=()=>{
    this.router.navigate(['perfil'],{queryParams: {user_id: this.user.id}});
  }

  irBuscador=()=>{
    this.router.navigateByUrl('buscador');
  }

  mostrarSubMenu(){
    const element = document.querySelector("#submenudd");

    if(element.classList.contains("show")) {
      $('#submenudd').removeClass("show");
    } else {
      $('#submenudd').addClass("show");
      $('#submenudd').css({"left":"-70px","top":"50%"});
    }
    
  }

  addPublicacion(){
    this.router.navigate(['publicacion'],{queryParams: {user_id: this.user.id}});
  }

}

